import React, { useState } from 'react';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Box,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

const GlobalSearchFilters = (props) => {
  const [search, setQuickSearch] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const searchOwnerEntity = (e) => {
    const searchedProperty = e.target.value;
    props.propertSearched(searchedProperty);
    setQuickSearch(searchedProperty);
  };

  const handleCityChange = (value) => {
    const newCity = selectedCity === value ? "" : value;
    if (newCity === "") {
      setSelectedCity('');
      props.setALLSearch(search, newCity, selectedState, selectedStatus);
    } else {
      setSelectedCity(newCity);
    }
  };

  const handleStateChange = (value) => {
    const newState = selectedState === value ? "" : value;
    if (newState === "") {
      setSelectedState('');
      props.setALLSearch(search, selectedCity, newState, selectedStatus);
    } else {
      setSelectedState(newState);
    }
  };

  const handleStatusChange = (value) => {
    const newStatus = selectedStatus === value ? "" : value;
    if (newStatus === "") {
      setSelectedStatus('');
      props.setALLSearch(search, selectedCity, selectedState, newStatus);
    } else {
      setSelectedStatus(newStatus);
    }
  };

  const searchButton = () => {
    props.setALLSearch(search, selectedCity, selectedState, selectedStatus);
  };

  return (
    <div className="container-fluid p-0">
      <div className="d-flex justify-content-between align-items-center">
        <Box
          display="flex"
          alignItems="center"
          className="viewHeader"
          sx={{ fontWeight: "bold", fontSize: "1.25rem", ml: 2, mb: 2, marginLeft: "170px" }}
        >
          Search Owners & Properties
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={2}
          bgcolor="white"
          borderRadius="8px"
          boxShadow="0px 1px 4px rgba(0, 0, 0, 0.2)"
          mx="auto"
          maxWidth="1200px"
          sx={{ marginRight: "150px" }}
        >
          <TextField
            variant="outlined"
            placeholder="Search by owner, entity name, entity address, register by"
            size="small"
            fullWidth
            InputProps={{
              disableUnderline: true,
              sx: { "& fieldset": { border: "none" } },
            }}
            sx={{ marginRight: 1, minWidth: 400 }}
            onChange={searchOwnerEntity}
          />

          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "#4a4a4a", borderWidth: 1, height: "28px", mx: 1 }}
          />

          <FormControl variant="outlined" size="small" sx={{ minWidth: 130, marginRight: 1 }}>
            <Select
              value={selectedCity || ""}
              displayEmpty
              sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "none" } }}
            >
              <MenuItem disabled value="">
                City
              </MenuItem>
              {props.City.filter(Boolean).map((option) => (
                <MenuItem key={option} value={option}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedCity === option}
                        onChange={() => handleCityChange(option)}
                        onClick={(e) => e.stopPropagation()}
                      />
                    }
                    label={option}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "#4a4a4a", borderWidth: 1, height: "28px", mx: 1 }}
          />

          <FormControl variant="outlined" size="small" sx={{ minWidth: 120, marginRight: 1 }}>
            <Select
              value={selectedState || ""}
              displayEmpty
              sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "none" } }}
            >
              <MenuItem disabled value="">
                State
              </MenuItem>
              {props.State.filter(Boolean).map((option) => (
                <MenuItem key={option} value={option}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedState === option}
                        onChange={() => handleStateChange(option)}
                        onClick={(e) => e.stopPropagation()}
                      />
                    }
                    label={option}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "#4a4a4a", borderWidth: 1, height: "28px", mx: 1 }}
          />

          <FormControl variant="outlined" size="small" sx={{ minWidth: 160, marginRight: 1 }}>
            <Select
              value={selectedStatus || ""}
              displayEmpty
              sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "none" } }}
            >
              <MenuItem disabled value="">
                Status
              </MenuItem>
              {props.Status.filter(Boolean).map((option) => (
                <MenuItem key={option} value={option.toLowerCase()}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedStatus === option.toLowerCase()}
                        onChange={() => handleStatusChange(option.toLowerCase())}
                        onClick={(e) => e.stopPropagation()}
                      />
                    }
                    label={option}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button variant="contained" color="primary" onClick={searchButton}>
            Search
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default GlobalSearchFilters;
