import React from 'react';
import PropTypes from 'prop-types';

const Frame1 = (props) => {
    const { className = '', overrides, isFrameOpen, onClose, defaultaddress, userData,currentPropertyaddress, ...rest } = props;
    if (!isFrameOpen) {
        return null;
    }
    return (
        <div className={props.isAddressSelected ? "ParkingSpots" : `frame-parent ${className}`} {...rest}>
            <div className="group-container">
                <img className="group-icon" alt="" src="Group91.png" />
                <b className="locate-property">
                    {props.isAddressSelected ? "MARK PARKING SPOTS AND ENTRANCES" : "LOCATE PROPERTY"}
                </b>
            </div>
            <div className={props.isAddressSelected ? "Markspots-Content" : "to-mark-pick-up"}>
                {props.isAddressSelected ?
                    "Select Points from the dropdown to mark on the map. Mark as many points as you need on the map. Pick parking spots/spaces that are the most likely to be vacant/open. After, you'll use the unavailability calendar to block off times when you do not want these parking spaces to be accessible by Robotaxis. If a Robotaxi arrives when a marked space is occupied, it will automatically move on to another GeoParking lot."
                    :
                    <>
                        {`Current property address: ${props.currentPropertyaddress}`}<br />
                        {"If the above street address is not the street address of the parking lot you are on boarding, then you need to use the search box in the top right to select the street address of your parking lot you are on boarding, once you have entered and see the proper property address in the Satellite image, then click save."}
                    </>
                }
            </div>
            <div className={props.isAddressSelected ? "close-Markspots" : "close-wrapper"} onClick={onClose} style={{ cursor: 'pointer' }}>
                <b className="close">Close</b>
            </div>
        </div>
    );
};

Frame1.propTypes = {
    className: PropTypes.string,
    overrides: PropTypes.object,
    isFrameOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default Frame1;
