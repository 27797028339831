import React from 'react';
import './MarkingAlert.css';

const MarkingAlert = (props) => {
    const { handleCancelalert, markingalert, areasMarkedCount, hasPSLEntry, ...rest } = props;

    let message;
    if (areasMarkedCount > 0 && !hasPSLEntry) {
        message = "Please select a PSL - Parking Space Location marker.";
    } else if (areasMarkedCount === 0 && !hasPSLEntry) {
        message = "Please select a marker to proceed.";
    }

    return (
        <div>
            {markingalert && (
                <div className="alert-overlay">
                    <div className="alert">
                        <p>{message}</p>
                        <div className="button-container">
                            <button onClick={() => handleCancelalert(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MarkingAlert;