import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const BootstrapDialog = ({props, open, onClose, title, content }) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle className='text-center'>{title}</DialogTitle>
            <DialogContent dividers style={{ maxHeight: '400px', overflowY: 'auto' }}>
                {content.map((section, index) => (
                    <div key={index}>
                        <Typography gutterBottom variant="h6">
                            {section.heading} <br />
                            {section.heading2}
                        </Typography>
                        {section.content.map((para, idx) => (
                            <Typography gutterBottom key={idx}>
                                <div dangerouslySetInnerHTML={{ __html: para }}></div>
                            </Typography>
                        ))}
                    </div>
                ))}
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center" }}>
                <Button className="secondary" autoFocus onClick={onClose} classes={{ root: "Secondary" }} >  <span>Close</span>  </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BootstrapDialog;
