import React, { useState, useEffect } from 'react';
import RobotaxiLogin from '../../Images/LoginImage.png';
import { useNavigate, Link, json } from 'react-router-dom';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PhoneInput from 'react-phone-number-input';
import '../../globalStyles/styles.css';
import { digitsWithAlphabetsRegex } from '../utils/regexPatterns'


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const EntityInformation = (props) => {
    const { overrides, setIsFlagSet, isFlagSet, formData, setFormData, ...rest } = props;
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const [userData, setdata] = useState({});
    const [rangeValue, setRangeValue] = useState(30);
    const [postId, setPostId] = useState(null);
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [errors, setErrors] = useState({});
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");
    const [emailrequestData, setEmailData] = useState({});
    const [searchValue, setSearchValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [addressdetails, setAddress] = useState();
    const [EmaiErr, setEmaiErr] = useState(false);
    const [mailingAddressZipCode, setMailingAddressZipCode] = useState("");
    const [zipcodeWarning, setZipcodeWarning] = useState("");
    const [mailingAddressCity, setMailingAddressCity] = useState("");

    useEffect(() => {
        const fetchStates = async () => {
            try {
                const response = await axios.get(
                    `https://api.countrystatecity.in/v1/countries/US/states`,
                    {
                        headers: {
                            "X-CSCAPI-KEY":
                                "MVFIREJGVWNpT0cwTWdtTTQ4eVhkQlBRRXB3YTNMdHpzYjE4aVF0cQ==",
                        },
                    }
                );
                var data = response.data.filter((country) =>
                    /^[a-zA-Z0-9]+$/.test(country.iso2)
                );
                // Sort the states alphabetically by name
                data.sort((a, b) => a.name.localeCompare(b.name));
                setStates(data);
            } catch (error) {
                console.error("Error fetching states:", error);
            }
        };

        fetchStates();
    }, []);

    useEffect(() => {
        if (searchValue?.trim() !== "") {
            // Fetch suggestions from API
            axios
                .get(
                    `https://atlas.microsoft.com/search/address/json?&subscription-key=iKSTDEFy2eA9QfhSETwDwwnQoR_36iCV5wv18ubSOBw&api-version=1.0&language=en-US&query=${searchValue}&filter=countryCode=US`
                )
                .then((response) => {
                    const results = response.data.results;
                    if (results && results.length > 0) {
                        // Extract suggestion labels from response and update state
                        const suggestedLocations = results
                            .filter((result) => result.address.countryCode === "US")
                            .map((result) => result.address);
                        setSuggestions(suggestedLocations);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching location suggestions:", error);
                });
        } else {
            setSuggestions([]);
        }
    }, [searchValue]);

    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        if (name == "EnityMailingAdderess") {
            if (name) {
                errors.EnityMailingAdderess = "";
                setFormData({ ...formData, [name]: value });
            } else {
                errors.EnityMailingAdderess = "Mailing Address is required";
            }
        }
        setSearchValue(e.target.value);
    };

    const handleAddressSelect = (address) => {
        const selectedState = states.find(
            (state) =>
                state.name.toLowerCase() ===
                address?.countrySubdivisionName?.toLowerCase()
        );
        var citylist;
        var selectedCity;
        var citylist;
        setMailingAddressZipCode(address?.postalCode? address?.postalCode : "");
        setMailingAddressCity(address?.municipality);
        errors.CityId = "";
        errors.Zipcode = "";
        setZipcodeWarning("");
        if (selectedState) {
            fetchCities(selectedState.iso2).then((citylist) => {
                selectedCity = citylist?.find(
                    (city) =>
                        city.name.toLowerCase() === address?.municipality?.toLowerCase()
                );
                setFormData((prevState) => ({
                    ...prevState,
                    CityId: selectedCity ? selectedCity.name : "",
                }));
            });
        }
        // Split the address by comma and select the first part
        const shortAddress = address?.freeformAddress.split(',')[0];
        setFormData((prevState) => ({
            ...prevState,
            EnityMailingAdderess: shortAddress, // Update with the short address
            Zipcode: address?.postalCode ?address?.postalCode : "",
            StateId: selectedState ? selectedState.iso2 : "",
        }));
        setSearchValue("");
        setSuggestions([]);
    };

    const handleContinue = (e) => {
        e.preventDefault();
        if (!props.settingpage) {
            setOpen(false);
            setEmailData({ EmailId: formData?.EmailId });
            props.sendOTPByEmail({ EmailId: props.formData?.EmailId });
        }
    };

    const fetchCities = async (data) => {
        try {
            const response = await axios.get(
                `https://api.countrystatecity.in/v1/countries/US/states/` +
                data +
                `/cities`,
                {
                    headers: {
                        "X-CSCAPI-KEY":
                            "MVFIREJGVWNpT0cwTWdtTTQ4eVhkQlBRRXB3YTNMdHpzYjE4aVF0cQ==",
                    },
                }
            );
            setCities(response.data);
            return response.data;
        } catch (error) {
            return [];
            console.error("Error fetching cities:", error);
        }
    };

    const validateForm1 = () => {
        const errors = {};
        const extendedAlphaNumericRegex = /^[a-zA-Z0-9À-ÿ\s\-,.]*$/;
        const digitRegex = /^\d*$/;

        if (!formData.EntityName) {
            errors.EntityName = "Entity Name is required";
        } else if (!extendedAlphaNumericRegex.test(formData.EntityName)) {
            errors.EntityName = "Entity Name cannot contain special characters";
        } else if (!digitsWithAlphabetsRegex.test(formData.EntityName) && formData.EntityName !== "") {
            errors.EntityName = "Entity Name cannot contain digits";
        }

        if (!formData.EnityMailingAdderess) {
            errors.EnityMailingAdderess = "Mailing Address is required";
        }
        if (!formData.Country) {
            errors.Country = "Country is required";
        }
        if (!formData.StateId) {
            errors.StateId = "State is required";
        }
        if (!formData.CityId) {
            errors.CityId = "City is required";
        }
        if (formData.CityId !== mailingAddressCity) {
            errors.CityId = "City does not match the address provided";
        }
        if (!formData.Zipcode) {
            errors.Zipcode = "ZIP Code is required";
        } else if (!digitRegex.test(formData.Zipcode)) {
            errors.Zipcode = "ZIP Code must contain only digits";
        } else if (formData.Zipcode.length > 5 || formData.Zipcode.length < 5) {
            errors.Zipcode = "ZIP Code must be 5 digits long";
        } else if (mailingAddressZipCode !== formData.Zipcode && mailingAddressZipCode !== "" && mailingAddressZipCode !== "") {
            setZipcodeWarning("");
            errors.Zipcode = "Zipcode does not match the address provided";
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const validateForm2 = async () => {
        const errors = {};
        const alphabetRegex = /^[A-Za-z\s\'']*$/;
        const emailExists = await checkEmailExistence(formData.EmailId);

        if (!formData.FirstName) {
            errors.FirstName = "First Name is required";
        } else if (!alphabetRegex.test(formData.FirstName)) {
            errors.FirstName = "First Name must contain only alphabets";
        }

        if (!formData.LastName) {
            errors.LastName = "Last Name is required";
        } else if (!alphabetRegex.test(formData.LastName)) {
            errors.LastName = "Last Name must contain only alphabets";
        }

        if (!formData.title) {
            errors.title = "Title is required";
        } else if (!alphabetRegex.test(formData.title)) {
            errors.title = "Title must contain only alphabets";
        }

        if (!formData.EmailId) {
            errors.EmailId = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.EmailId)) {
            errors.EmailId = "Email is invalid";
        }
        else if (emailExists) {
            errors.EmailId = "Email already exists";
        }

        if (!formData.MobileNumber) {
            errors.MobileNumber = "Mobile Number is required";
        } else {
            const phoneNumber = formData.MobileNumber.replace(/\D/g, "");
            if (phoneNumber.length !== 11 || !phoneNumber.startsWith("1")) {
                errors.MobileNumber = "Mobile number is invalid";
            }
        }
        if (!formData.OfficeNumber) {
            // errors.OfficeNumber = "Mobile Number is required";
        } else {
            const OfficeNumber = formData.OfficeNumber.replace(/\D/g, "");
            if (OfficeNumber.length !== 11 || !OfficeNumber.startsWith("1")) {
                errors.OfficeNumber = "OfficeNumber number is invalid";
            }
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const backgroundStyle = {
        background: `linear-gradient(90deg, green ${rangeValue}%, rgb(230 224 224) )`,
    };
    const handleButtonClick = (e) => {
        e.preventDefault();
        if (validateForm1()) {
            setIsFlagSet(true);
            setRangeValue(60);
        }
    };

    const goback = (e) => {
        e.preventDefault();
        setIsFlagSet(false);
        setRangeValue(30);
    };

    const registrationButtonClick = async (e) => {
        e.preventDefault();
        if (await validateForm2()) {
            try {
                if (errors.EmailId) {
                    setErrors({ ...errors, EmailId: "Email already exists" });
                } else {
                    // localStorage.setItem("formData", JSON.stringify(formData));
                    setOpen(true);
                }
            } catch (error) {
                console.error("Error checking email existence:", error.message);
                setErrors({ ...errors, EmailId: "Email already exists" });
            }
        }
    };

    async function checkEmailExistence(email) {
        setLoading(true);
        const emailid = { EmailId: email };
        const requestOptions = {
            method: "POST",
            headers: { "x-functions-key": `${hostHeader}` },
             body: JSON.stringify(emailid)
        };

        try {
            const response = await axios.post(
                `${hostPath}/api/CheckEmailIdExist`,
                requestOptions.body,
                { headers: requestOptions.headers }
            );

            if (response.data === true) {
                setEmaiErr(true)
                return true;
            } else {
                setEmaiErr(false)
                return false;
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false); // Set loading state back to false after receiving the response
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        const alphabetRegex = /^[A-Za-z\s\'']*$/;
        const alphaNumericRegex = /^[a-zA-Z0-9\s]+$/;
        const extendedAlphaNumericRegex = /^[a-zA-Z0-9À-ÿ\s\-,.]*$/;
        const digitRegex = /^\d*$/;
        if (name === "EntityName") {
            if (!extendedAlphaNumericRegex.test(value) && value !== "") {
                errors.EntityName = "Entity Name cannot contain special characters";
            } else if (!digitsWithAlphabetsRegex.test(value)) {
                errors.EntityName = "Entity Name cannot contain digits";
            } else {
                errors.EntityName = "";
            }
            setFormData({ ...formData, [name]: value });
        }
        if (name === "Zipcode") {
            if (!digitRegex.test(value)) {
                errors.Zipcode = "ZIP Code must contain only digits";
            } else if ((value.length > 5 || value.length < 5) && value !== "") {
                errors.Zipcode = "ZIP Code must be 5 digits long";
            } else if (mailingAddressZipCode !== value && mailingAddressZipCode !== "" && value !== "") {
                setZipcodeWarning("Zipcode does not match the address provided");
                errors.Zipcode = "";
            }
            else {
                errors.Zipcode = "";
                setZipcodeWarning("");
            }
            setFormData({ ...formData, [name]: value });
        }

        if (name === "FirstName") {
            if (!alphabetRegex.test(value)) {
                errors.FirstName = "First Name must contain only alphabets";
            } else {
                errors.FirstName = "";
                setFormData({ ...formData, [name]: value });
            }
        }

        if (name === "LastName") {
            if (!alphabetRegex.test(value)) {
                errors.LastName = "Last Name must contain only alphabets";
            } else {
                errors.LastName = "";
                setFormData({ ...formData, [name]: value });
            }
        }
        if (name === "title") {
            if (!alphabetRegex.test(value)) {
                errors.title = "Title must contain only alphabets";
            } else {
                errors.title = "";
                setFormData({ ...formData, [name]: value });
            }
        }

        if (name === "EmailId") {
            if (!/\S+@\S+\.\S+/.test(value)) {
                errors.EmailId = "Email is invalid";
            }
            else {
                errors.EmailId = "";
                setEmail((prev) => value);
                setFormData({ ...formData, [name]: value });
            }
        }

        if (name === "MobileNumber") {
            setFormData({ ...formData, [name]: value });
            setErrors({ ...errors, MobileNumber: "" })
        }

        else if (name === "OfficeNumber") {
            setFormData({ ...formData, [name]: value });
            setErrors({ ...errors, OfficeNumber: "" })
        }
        else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const stateChange = (e) => {
        const { name, value } = e.target;
        if (value) {
            fetchCities(value);
        }
        if (name === 'StateId') {
            setFormData({ ...formData, [name]: value });
            errors.StateId = '';
        }
        else {
            errors.StateId = 'State is required';
        }
    };
    const cityChange = (e) => {
        const { name, value } = e.target;
        if (value) {
            fetchCities(value);
        }
        if (name === 'CityId') {
            let errorMessage = '';
            if (value && value !== mailingAddressCity) {
                errorMessage = "City does not match the address provided";
            }
            setFormData({ ...formData, [name]: value });
            errors.CityId = errorMessage;
        } else {
            errors.CityId = 'City is required';
        }
    };
    const handleradiobutton = (event) => {
        setSelectedValue(event.target.value);
        if (event.target.value === 'email') {
            console.log('event.target.email', event.target.value);
        } else if (event.target.value === 'phone') {
            console.log('event.target.phone', event.target.value);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedValue("");
    };

    return (
        <div className="container-fluid p-0 entity">
            <div className="row m-0">
                <div className="col-md-6 p-0">
                    <img src={RobotaxiLogin} alt="Login" className="GeoparkingImage" />
                </div>
                <div className="col-md-6 p-0 mb-3">
                    <div className="contain">
                        <div className="registersteps"> Step {isFlagSet ? 2 : 1} of 3</div>
                        <input
                            type="range"
                            className="range-input mb-3"
                            value={rangeValue}
                            min="0"
                            max="100"
                            style={{
                                width: "80%",
                                height: "5px",
                                padding: "0",
                                borderRadius: "5.5px",
                                background: `linear-gradient(to right, green ${rangeValue}%, lightgray ${rangeValue}%)`,
                                outline: "none",
                            }}
                        />

                        <div className="login-form">
                            <div className="login">
                                <h3 className="loginheader textleft">Entity Information</h3>
                                <p className="mb-4 loginDescription textleft">
                                    Please provide the Parking Lot(s) owner/management entity
                                    information
                                </p>
                            </div>

                            {isFlagSet ? (
                                <form onSubmit={registrationButtonClick}>
                                    <div className="Username mb-2 textleft">
                                        <label
                                            htmlFor="first-name"
                                            className="userName loginlabel "
                                        >
                                            First Name
                                        </label>
                                        <span className="mandatorymark loginlabel">*</span>
                                        <input
                                            type="text"
                                            className={`form-control ${formData.FirstName ? "input-active" : ""
                                                }`}
                                            id="first-name"
                                            maxLength={50}
                                            placeholder="Enter First Name"
                                            name="FirstName"
                                            value={formData.FirstName}
                                            onChange={handleChange}
                                        />
                                        {errors.FirstName && (
                                            <div className="error-message mandatorymark">
                                                {errors.FirstName}
                                            </div>
                                        )}
                                    </div>
                                    <div className="Password mb-2 textleft">
                                        <label htmlFor="last-name" className="userName loginlabel">
                                            Last Name
                                        </label>
                                        <span className="mandatorymark loginlabel">*</span>
                                        <input
                                            type="text"
                                            className={`form-control ${formData.LastName ? "input-active" : ""
                                                }`}
                                            id="last-name"
                                            maxLength={50}
                                            placeholder="Enter Last Name "
                                            name="LastName"
                                            value={formData.LastName}
                                            onChange={handleChange}
                                        />
                                        {errors.LastName && (
                                            <div className="error-message mandatorymark">
                                                {errors.LastName}
                                            </div>
                                        )}
                                    </div>
                                    <div className="Password mb-2 textleft">
                                        <label htmlFor="title" className="userName loginlabel">
                                            Title
                                        </label>
                                        <span className="mandatorymark loginlabel">*</span>
                                        <input
                                            type="text"
                                            className={`form-control ${formData.title ? "input-active" : ""
                                                }`}
                                            id="title"
                                            maxLength={50}
                                            placeholder="Enter Title "
                                            name="title"
                                            value={formData.title}
                                            onChange={handleChange}
                                        />
                                        {errors.title && (
                                            <div className="error-message mandatorymark">
                                                {errors.title}
                                            </div>
                                        )}
                                    </div>

                                    <div className="Password mb-2 textleft">
                                        <label htmlFor="email" className="userName loginlabel">
                                            Email
                                        </label>
                                        <span className="mandatorymark loginlabel">*</span>
                                        <input
                                            type="text"
                                            className={`form-control ${formData.EmailId ? "input-active" : ""
                                                }`}
                                            id="email"
                                            placeholder="Enter Email "
                                            name="EmailId"
                                            value={formData.EmailId}
                                            onChange={handleChange}
                                        />
                                        {errors.EmailId && (
                                            <div className="error-message mandatorymark">
                                                {errors.EmailId}
                                            </div>
                                        )}
                                    </div>
                                    <div className="Password mb-2 textleft">
                                        <label
                                            htmlFor="MobileNumber"
                                            className="userName loginlabel"
                                        >
                                            Mobile Phone
                                        </label>
                                        <span className="mandatorymark loginlabel">*</span>
                                        <PhoneInput
                                            international
                                            countryCallingCodeEditable={false}
                                            name="MobileNumber"
                                            maxLength={15}
                                            value={formData.MobileNumber}
                                            id="MobileNumber"
                                            placeholder="Enter phone number"
                                            defaultCountry='US'
                                            countries={['US']}
                                            onChange={(value) => handleChange({ target: { name: "MobileNumber", value } })}
                                            className="small-phone-input" />
                                        {errors.MobileNumber && (
                                            <div className="error-message mandatorymark">
                                                {errors.MobileNumber}
                                            </div>
                                        )}
                                    </div>
                                    <div className="Password mb-2 textleft">
                                        <label
                                            htmlFor="MobileNumber"
                                            className="userName loginlabel"
                                        >
                                            Office Phone
                                        </label>
                                        <PhoneInput
                                            international
                                            countryCallingCodeEditable={false}
                                            name="OfficeNumber"
                                            maxLength={15}
                                            value={formData.OfficeNumber}
                                            id="OfficeNumber"
                                            placeholder="Enter office number"
                                            defaultCountry='US'
                                            countries={['US']}
                                            onChange={(value) => handleChange({ target: { name: "OfficeNumber", value } })}
                                            className="small-phone-input" />
                                        {errors.OfficeNumber && (
                                            <div className="error-message mandatorymark">
                                                {errors.OfficeNumber}
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className=" mb-3 d-flex justify-content-between "
                                        style={{ textAlign: "end" }}
                                    >
                                        <Link
                                            to="/entityinformation"
                                            className="joingeo"
                                            onClick={goback}
                                        >
                                            Go Back
                                        </Link>
                                        <button type="submit" className="nextBtn">
                                            Next
                                        </button>
                                    </div>
                                    <div className="joinus remember" style={{ textAlign: "end" }}>
                                        Already have an account?
                                        <a href="/login" className="joingeo">
                                            {" "}
                                            Log in
                                        </a>{" "}
                                        here.
                                    </div>
                                </form>
                            ) : (
                                <form onSubmit={handleButtonClick}>
                                    <div className="Username mb-2 textleft">
                                        <label htmlFor="username" className="userName loginlabel ">
                                            Entity Name
                                        </label>
                                        <span className="mandatorymark loginlabel">*</span>
                                        <input
                                            type="text"
                                            className={`form-control ${formData.EntityName ? "input-active" : ""
                                                }`}
                                            id="username"
                                            maxLength={50}
                                            name="EntityName"
                                            value={formData.EntityName}
                                            onChange={handleChange}
                                            placeholder="Enter Entity Name"
                                        />
                                        {errors.EntityName && (
                                            <div className="error-message mandatorymark">
                                                {errors.EntityName}
                                            </div>
                                        )}
                                    </div>
                                    <div className="Password mb-2 textleft">
                                        <label htmlFor="password" className="userName loginlabel">
                                            Entity Mailing address
                                        </label>
                                        <span className="mandatorymark loginlabel">*</span>
                                        <input
                                            type="text"
                                            className={`form-control ${formData.EnityMailingAdderess ? "input-active" : ""
                                                }`}
                                            id="password"
                                            maxLength={100}
                                            placeholder="Enter address "
                                            name="EnityMailingAdderess"
                                            value={formData.EnityMailingAdderess}
                                            onChange={(e) => {
                                                handleSearchChange(e);
                                            }}
                                        />
                                        {suggestions.length > 0 && (
                                            <ul className="search-results">
                                                {suggestions.map((result) => (
                                                    <li onClick={() => handleAddressSelect(result)}>
                                                        {result.freeformAddress}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                        {errors.EnityMailingAdderess && (
                                            <div className="error-message mandatorymark">
                                                {errors.EnityMailingAdderess}
                                            </div>
                                        )}
                                    </div>
                                    <div className="Password mb-2 textleft">
                                        <label htmlFor="password" className="userName loginlabel">
                                            Entity Mailing address 2 (optional)
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-control ${formData.EnityMailingAdderess2 ? "input-active" : ""
                                                }`}
                                            id="password"
                                            maxLength={50}
                                            placeholder="Enter address"
                                            name="EnityMailingAdderess2"
                                            value={formData.EnityMailingAdderess2}
                                            onChange={handleChange}
                                        />
                                        {/* {errors.UserName && <div className="error-message mandatorymark">{errors.UserName}</div>} */}
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-6 Password mb-2 textleft">
                                            <label htmlFor="password" className="userName loginlabel">
                                                Country
                                            </label>
                                            <span className="mandatorymark loginlabel">*</span>
                                            <input
                                                type="text"
                                                className={`form-control ${formData.Country ? "input-active" : ""
                                                    }`}
                                                id="password"
                                                placeholder="Enter Country "
                                                name="Country"
                                                disabled={true}
                                                value={formData.Country}
                                                onChange={handleChange}
                                                readOnly
                                                style={{ cursor: "not-allowed" }}
                                            />
                                            {errors.Country && (
                                                <div className="error-message mandatorymark">
                                                    {errors.Country}
                                                </div>
                                            )}
                                        </div>
                                        <div className=" col-md-6 Password mb-2 textleft">
                                            <label htmlFor="password" className="userName loginlabel">
                                                State
                                            </label>
                                            <span className="mandatorymark loginlabel">*</span>
                                            <select
                                                className={`form-control ${formData.StateId ? "input-active" : ""
                                                    }`}
                                                id="state"
                                                name="StateId"
                                                value={formData.StateId}
                                                onChange={stateChange}
                                                disabled={formData.StateId ? true : false}
                                                style={{ cursor: formData.StateId ? "not-allowed" : "pointer" }}
                                            >
                                                <option value="">Select State</option>
                                                {states.map((state) => (
                                                    <option key={state.id} value={state.iso2}>
                                                        {state.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.StateId && (
                                                <div className="error-message mandatorymark">
                                                    {errors.StateId}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className=" col-md-6 Password mb-3 textleft">
                                            <label htmlFor="password" className="userName loginlabel">
                                                City
                                            </label>
                                            <span className="mandatorymark loginlabel">*</span>
                                            <select
                                                className={`form-control ${formData.CityId ? "input-active" : ""
                                                    }`}
                                                id="city"
                                                name="CityId"
                                                value={formData.CityId}
                                                onChange={cityChange}
                                            >
                                                <option value="">Select City</option>
                                                {cities.map((city) => (
                                                    <option key={city.id} value={city.iso2}>
                                                        {city.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.CityId && (
                                                <div className="error-message mandatorymark">
                                                    {errors.CityId}
                                                </div>
                                            )}
                                        </div>
                                        <div className=" col-md-6 Password mb-2 textleft">
                                            <label htmlFor="password" className="userName loginlabel">
                                                ZIP Code
                                            </label>
                                            <span className="mandatorymark loginlabel">*</span>
                                            <input
                                                type="text"
                                                maxLength="5"
                                                className={`form-control ${formData.Zipcode ? "input-active" : ""
                                                    }`}
                                                id="password"
                                                placeholder="Enter ZIP Code "
                                                name="Zipcode"
                                                value={formData.Zipcode}
                                                onChange={handleChange}
                                            />
                                            {errors.Zipcode && (
                                                <div className="error-message mandatorymark">
                                                    {errors.Zipcode}
                                                </div>
                                            )}
                                            {zipcodeWarning && (
                                                <div className="error-message mandatorymark">
                                                    {zipcodeWarning}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className=" mb-3" style={{ textAlign: "end" }}>
                                        <button type="submit" className='nextBtn'>Next</button>
                                    </div>
                                    <div className='joinus remember' style={{ textAlign: "end" }}>
                                        Already have an account?
                                        <a href='/Login' className='joingeo'> Log in</a> here.</div>
                                </form>
                            )

                            }

                        </div>
                    </div>
                </div>
            </div>
            <BootstrapDialog
                onClose={handleContinue}
                classes={{ paper: 'custom-paper-class' }}
                aria-labelledby="customized-dialog-title"

                dividers sx={{
                    textAlign: 'start',
                    overflowY: 'auto',
                    padding: '5rem 5rem',
                    borderRadius: '5px'
                }}
                open={open}
            >
                <DialogTitle dividers sx={{ textAlign: 'start', color: 'rgba(51, 51, 51, 1)', fontSize: "24px", fontWeight: "700", marginLeft: "20px" }} id="customized-dialog-title">
                    Email and Phone Verification
                </DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent style={{ border: "none", padding: "12px 37px" }}>
                    <Typography gutterBottom>
                        <span>
                            {" "}

                        </span>
                    </Typography>
                    <div>
                    </div>
                    <Typography gutterBottom>
                        <span>
                            For your security, you will need to complete a two-step verification process.<br />
                            You will receive two verification codes: one on your registered email address: {formData?.EmailId?.toLowerCase()} {" "}
                            and one on your registered phone number: {formData?.MobileNumber}.
                        </span>
                    </Typography>
                </DialogContent>

                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button
                        className='mb-3'
                        autoFocus
                        classes={{ root: 'Secondary' }}
                        sx={{ width: '150px', height: '50px', backgroundColor: '#6BCD55', color: '#000', fontFamily: 'Montserrat', fontSize: '17px', fontStyle: 'normal', fontWeight: 700, backgroundColor: "lightgrey", '&:hover': { backgroundColor: '#A9A9A9' } }}
                    >
                        <span style={{ textTransform: 'capitalize' }}>Cancel</span>
                    </Button>
                    <Button
                        className='mb-3'
                        autoFocus
                        onClick={handleContinue}
                        classes={{ root: 'Secondary' }}
                        sx={{ width: '150px', height: '50px', backgroundColor: '#6BCD55', color: '#000', fontFamily: 'Montserrat', fontSize: '17px', fontStyle: 'normal', fontWeight: 700, '&:hover': { backgroundColor: '#5aa949' } }}
                    // disabled={!selectedValue}
                    >
                        <span style={{ textTransform: 'capitalize' }}>Continue</span>
                    </Button>
                </DialogActions>
            </BootstrapDialog>

        </div>
    );
};

export default EntityInformation;
