import React, { useEffect, useState, useRef } from 'react';
import DataTable from "react-data-table-component";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import Navbar from '../NavBar/Navbar';
import { useNavigate } from 'react-router-dom';
import FadeLoader from "react-spinners/FadeLoader";
import { IoEyeSharp } from "react-icons/io5";
import { FcCancel } from "react-icons/fc";
import StatusDropdown from '../MyWorklist/StatusDropdown'
import { BiSortAlt2 } from "react-icons/bi";
import Popover from '@mui/material/Popover';
import { fetchParkinglotDetailsbyentityid } from '../../Services/parkinglotService'
import OwnerPropertyImage from '../../Images/Frame.jpg';
const OwnerParkinglotRegistration = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);  // State to track anchor element for the popup
    const [selectedRowData, setSelectedRowData] = useState(null);
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const location = useLocation();
    const [parkingLotBookingDetails, setParkingLotBookingDetails] = useState([]);
    const [filter, setFilter] = useState([]);
    const [search, setSearch] = useState("");
    const [popupVisible, setPopupVisible] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
    const actionRef = useRef(null);
    const [open, setOpen] = React.useState(false);
    const [userData, setUserdata] = useState();
    const [rowData, setRowData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(''); // This will store the selected status
    const [sortDirection, setSortDirection] = useState('asc'); // State for sorting direction
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));
    const navigate = useNavigate();
    const stateLogedInUser = props.entityId;
    useEffect(() => {
        const fetchParkinglotdetails = async () => {
            setLoader(true);  // Show loader before fetching data
            try {
                const parkingDetails = await fetchParkinglotDetailsbyentityid(stateLogedInUser);
                setParkingLotBookingDetails(parkingDetails);  // Set fetched data
            } catch (error) {
                console.error("Error fetching parking lot details:", error);
            } finally {
                setLoader(false);  
            }
        };
        
        fetchParkinglotdetails();  
    }, []);  
    useEffect(() => {
        let result = parkingLotBookingDetails;

        // Filter by search query
        if (search) {
            result = result.filter((item) => {
                return (item.parkingLotAddress || item.createdDate).toLowerCase().includes(search.toLowerCase());
            });
        }

        // Filter by selected status
        if (selectedStatus.length > 0) {
            result = result.filter((item) => selectedStatus.includes(item.status));
        }

        setFilter(result);
    }, [search, parkingLotBookingDetails, selectedStatus]);
    const handleDelete = (val, typeOfButton) => {
        let message;
        let message2;
        let status;
        if (typeOfButton === 'trash') {
            message = 'Are you sure you want to delete this property? This action is irreversible.';
            message2 = 'Once you proceed, you will not be able to undo this action. Please confirm if you wish to continue.'
            status = 'delete';
        } else if (typeOfButton === 'cancel') {
            message = 'Are you sure you want to mark this property as inactive?';
            message2 = 'You can make it active again in the future if you wish. Please confirm if you wish to continue.'
            status = 'Inactive'
        }
        else if (typeOfButton === 'active') {
            message = 'Are you sure you want to mark this property as active?';
            message2 = 'Please confirm if you wish to continue.'
            status = 'Submitted'
        }

        let data = { EmailId: val.emailId, Parking_Lot_ID: val.parking_Lot_ID, message, message2, status, val };
        setRowData(data);
        setOpen(true);
        setPopupVisible(false);
    };
    const handleConfirm = (text, status, val) => {
        if (text === 'confirm') {
            if (status === 'delete') {
                const requestOptions = {
                    method: 'POST',
                    headers: { "x-functions-key": hostHeader },
                    body: JSON.stringify(rowData)
                };
                axios.post(`${hostPath}/api/DeleteParkingLotDetailsByEmailId`, requestOptions.body, { headers: requestOptions.headers })
                    .then(response => {
                        if (response.data) {
                            if (response.data.status) {
                                const newdata = parkingLotBookingDetails.filter((item) => item.parking_Lot_ID !== rowData.Parking_Lot_ID);
                                setParkingLotBookingDetails(newdata);
                                setFilter(newdata);
                                setOpen(false);
                            }
                            else {
                                alert(response.data.responseMessage);
                            }
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }
            else if (status === 'Inactive' || status === 'Submitted') {
                const BankDetails = val?.bankDetails;
                const CoardinateDetails = val?.coardinateDetails;
                const Scheduleavailability = val?.scheduleavailability
                const parkinglot = {
                    "EmailId": val.emailId,
                    "EmpId":val.empId,
                    "EntityRegistrationID": val?.entityRegistrationID,
                    "ParkingLotAddress": val?.parkingLotAddress,
                    "Parking_Lot_ID": val?.parking_Lot_ID,
                    "id": val?.id,
                    "CreatedDate": val?.createdDate,
                    "Status": status,
                    "Comment": val?.comment,
                    "StreetName": val?.streetName,
                    BankDetails,
                    CoardinateDetails,
                    Scheduleavailability
                };
                BankDetails.EntityRegistrationID = val?.entityId;
                axios.post(`${hostPath}/api/UpdateParkingLotDetails`, parkinglot, {
                    headers: {
                        "x-functions-key": hostHeader
                    }
                })
                    .then(response => {
                        if (response.data) {
                            //fetchParkinglotDetails();
                            setOpen(false);
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });

            }
        }
        else if (text == 'cancel') {
            setOpen(false);
        }
    };

    const handleEdit = (e, val, typeOfButton) => {
        setSelectedRowData(val);
        setAnchorEl(e.currentTarget);  // Set the clicked element as the anchor for the popup
    };

    const handleEdittomap = (val) => {
        //Implement the edit functionality
        props.onSetParkingLotObj(val);
        props.onSetDefaultAddress(val?.parkingLotAddress);
        const transformedAreas = val?.coardinateDetails?.map(details => ({
            Coordinates: {
                Title: details?.title || 'Unknown',
                Latitude: parseFloat(details?.latitude) || 0,
                Longitude: parseFloat(details?.longitude) || 0
            },
            Name: details?.title || 'Unknown',
            id: parseInt(details?.id, 10) || 0,
            latandlong: `Latitude: ${parseFloat(details?.latitude) || 0} Longitude: ${parseFloat(details?.longitude) || 0}`
        }));
        props.onSetAreasMarked(transformedAreas);
        props.handleSetEdit(true);
        setPopupVisible(false);
    };

    const handleActionClick = (row, event) => {
        setSelectedRow(row);
        const rect = event.target.getBoundingClientRect();
        setPopupPosition({
            top: rect.bottom + window.scrollY - 75,  // Adding some offset
            right: rect.right + window.scrollX - 1450 // Center align with dots
        });
        setPopupVisible(true);
    };
    const handleStatusChange = (selectedStatus) => {
        setSelectedStatus(selectedStatus);
    };
    const handleSort = () => {
        // Toggle the sorting direction
        const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        setSortDirection(newSortDirection);

        // Sort the data based on the 'createdDate' field
        const sortedData = [...filter].sort((a, b) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);

            if (newSortDirection === 'asc') {
                return dateA - dateB;
            } else {
                return dateB - dateA;
            }
        });

        setFilter(sortedData);
    };
    const columns = [
        {
            name: "Nickname",
            selector: (row) => row.streetName,
        },
        {
            name: "Street Address",
            cell: (row) => (
                <div
                    style={{ cursor: 'pointer' }}  // Style to indicate it's clickable
                    onClick={(e) => handleEdittomap(row)} // Call function when clicked
                >
                    {row.parkingLotAddress}
                </div>
            ),
        },
        {
            name: "Lots Allocated",
            selector: (row) => "3",
        },
        {
            name: "Lots Occupied",
            selector: (row) => "2",
        },
        {
            name: (
                <div style={{ display: 'flex', alignItems: 'center' }} onClick={handleSort}>
                    Last Updated <BiSortAlt2 style={{ marginLeft: '5px' }} />
                </div>
            ),
            selector: (row) => row.createdDate,
        },
        {
            name: (
                <div style={{ display: 'flex', alignItems: 'start' }}>
                    <StatusDropdown handleStatusChange={handleStatusChange} />
                </div>
            ),
            selector: (row) => row?.status,
        },
        {
            name: "Actions",
            cell: (row) => (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0px' }}>
                    {row.status === 'Inactive' ? (
                        <>
                            {/* Show "Activate" icon for inactive status */}
                            <FiEdit3 title="Activate" aria-label="Activate"
                                style={{
                                    width: "38px",
                                    height: "25px",
                                    padding: "2px 2px",
                                    cursor: "pointer",
                                    justifyContent: "center",
                                    color: "green"
                                }}
                                onClick={() => handleDelete(row, 'active')}  // Change status to Active
                            />
                        </>
                    ) : (
                        <>
                            {/* Show default edit and delete icons for active status */}
                            <FiEdit3 title="Edit" aria-label="Edit"
                                style={{
                                    width: "38px",
                                    height: "25px",
                                    padding: "2px 2px",
                                    cursor: "pointer",
                                    justifyContent: "center",
                                }}
                                onClick={(e) => handleEdit(e, row)} />
                            <FiTrash2 title="Delete" aria-label="Delete"
                                style={{
                                    width: "38px",
                                    height: "25px",
                                    padding: "2px 2px",
                                    cursor: "pointer",
                                    justifyContent: "center",
                                }}
                                onClick={() => handleDelete(row, 'trash')}  // Pass 'trash' as the type
                            />
                            <FcCancel title="Inactive" aria-label="Inactive"
                                style={{
                                    width: "38px",
                                    height: "25px",
                                    padding: "2px 2px",
                                    cursor: "pointer",
                                    justifyContent: "center",
                                }}
                                onClick={() => handleDelete(row, 'cancel')}  // Pass 'cancel' as the type
                            />
                        </>
                    )}
                </div>
            ),
        },
    ];

    const tableHeaderStyle = {
        headCells: {
            style: {
                height: "90px",
                fontWeight: 800,
                fontSize: "16px",
                backgroundColor: "white",
                lineHeight: "19.2px",
                fontFamily: "Lato",
                textAlign: "left",
                borderBottom: "none"
            },
        },
    };

    const navigateToDashboard = () => {
        navigate('/dashboard');
    };

    const handleClose = () => {
        setAnchorEl(null);  // Close the popup
    };

    const Isopen = Boolean(anchorEl);  // Boolean to check if the popup is open
    const id = Isopen ? 'edit-popover' : undefined;

    const handleOptionClick = (option) => {
        props.onSetEditOption(option);
        setAnchorEl(null);  // Close the popup after selecting an option
        handleEdittomap(selectedRowData);
        if (option === 'parkingspaces') {
            handleEdittomap(selectedRowData);
        }
    };
    return (
        <div>
            {loader && (
                <div className="loader-overlay">
                    <FadeLoader />
                </div>
            )}
                {parkingLotBookingDetails.length>0?(
                <div>
                    <div className='container-fluid p-0'>
                        {/* Heading with two lines */}
                        <div className="lotmanagement">
                        <div style={{ textAlign: 'center', position: 'relative',top:'-35px' }}>
                        <hr style={{ border: 'none', borderTop: '1px solid #000',  margin: '0 20px', 
                            width: '80%',  padding: '0 15%',margin: '0 auto',  }} /> 
                        <h3 style={{
                                display: 'inline-block',
                                position: 'relative',
                                backgroundColor: '#fff',
                                padding: '0 10px',
                                top: '-12px',  // Move the heading slightly up
                                fontSize: '18px',
                                fontWeight: '600'
                            }}>
                                Properties
                            </h3>
                        </div>
                            <React.Fragment>
                            <div className="table-container">
                                <DataTable
                                    customStyles={{
                                        headCells: tableHeaderStyle.headCells,
                                        cells: {
                                            style: {
                                                fontFamily: 'Lato',
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                lineHeight: '18.6px',
                                                textAlign: 'left',
                                                height: "50px",
                                                borderBottom: "none",
                                            },
                                        },
                                    }}
                                    columns={columns}
                                    data={filter.length ? filter : []}  // Pass empty array to prevent row rendering
                                    pagination
                                    fixedHeader
                                    selectableRowsHighlight
                                    highlightOnHover
                                    persistTableHead
                                    style={{ width: '50%' }} 
                                    noDataComponent={
                                        <div style={{ padding: '20px 0', textAlign: 'center' }}>
                                            No records found
                                        </div>
                                    }
                                />
                                </div>
                            </React.Fragment>
                        </div>
                    </div>
                    <Dialog
                        classes={{ paper: 'custom-paper-class-delete' }}
                        aria-labelledby="customized-dialog-title"
                        dividers
                        open={open}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <DialogTitle dividers sx={{ textAlign: 'start', color: "#000;", fontSize: "24px", fontWeight: "700", padding: "5px 24px " }} id="customized-dialog-title">
                            {rowData?.message}  {/* Display different messages based on the button clicked */}
                        </DialogTitle>
                        <DialogContent style={{ border: "none", padding: "5px 22px", color: "#000;" }}>
                            <Typography gutterBottom>
                                <span>{rowData?.message2}</span>
                            </Typography>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: 'right' }}>
                            <Button
                                className='custom-button-cancel'
                                onClick={() => handleConfirm('cancel')}
                            >
                                Cancel
                            </Button>
                            <Button
                                className='custom-button-delete'
                                onClick={() => handleConfirm('confirm', rowData?.status, rowData?.val)}
                            >
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Popover
                        id={id}
                        open={Isopen}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',  // Popup will appear to the left of the clicked icon
                            height: '500px',
                            width: '500px'
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'right',  // Popup will align to the right side of the anchor element
                        }}
                    >
                        <div style={{ padding: '10px' }}> {/* Additional padding for content */}
                            <Button
                                variant="text"
                                onClick={() => handleOptionClick('parkingspaces')}
                                style={{ display: 'block', textAlign: 'left', width: '100%', textTransform: 'none' }} // Ensure alignment
                            >
                                Parking Spaces
                            </Button>
                            <Button
                                variant="text"
                                onClick={() => handleOptionClick('parkingschedule')}
                                style={{ display: 'block', textAlign: 'left', width: '100%', textTransform: 'none' }} // Ensure alignment
                            >
                                Parking Schedule
                            </Button>
                            <Button
                                variant="text"
                                onClick={() => handleOptionClick('bankdetails')}
                                style={{ display: 'block', textAlign: 'left', width: '100%', textTransform: 'none' }} // Ensure alignment
                            >
                                Bank Details
                            </Button>
                        </div>
                    </Popover>
                </div>)
                : (
             <div className="no-property-container">
              <img src={OwnerPropertyImage} alt="No properties registered" className="building-image" />
              <p className="no-property-text">
                No property registered yet! Click{' '}
                <a href="/register-property" className="register-link">Register New Property</a>{' '}
                to get started and add your first property.
              </p>
            </div> 
                )}
        </div>
    );
};

export default OwnerParkinglotRegistration;
