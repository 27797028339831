import React, { useState } from 'react';
import { Select, MenuItem, Checkbox, ListItemText, FormControl, InputLabel } from '@mui/material';

const options = [
  'Submitted',
  'Approved',
  'Inactive',
  'Rejected',
  'Draft'
];

const StatusDropdown = (props) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const newSelectedOptions = typeof value === 'string' ? value.split(',') : value;
    setSelectedOptions(newSelectedOptions);
    props.handleStatusChange(newSelectedOptions);
  };

  return (
    <FormControl fullWidth sx={{ width: '100px' }}>
      <InputLabel
        id="dropdown-checkbox-label"
        sx={{
          color: 'black',
          fontWeight: 'bold',
          "&.Mui-focused": { color: 'black' }
        }}
        shrink={false}  // Keep the label from shrinking
      >
        Status
      </InputLabel>
      <Select
        sx={{
          borderRadius: 0,
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none"
          },
          color: 'black'
        }}
        labelId="dropdown-checkbox-label"
        multiple
        value={selectedOptions}
        onChange={handleChange}
        renderValue={() => ''}
        label="Status"
        displayEmpty
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            value={option}
            sx={{ padding: '4px 8px', width: '150px' }}  // Adjust padding to reduce gap
          >
            <Checkbox checked={selectedOptions?.indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StatusDropdown;
