import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';
import { extendedAlphaNumericRegex, digitRegex, alphabetRegex } from '../utils/regexPatterns';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './OwnerRegistration.css';
import { entityRegistration, checkEmailExistence, fetchUserDetailsByEmailId, UpdateProfile, UpdatePassword,UpdateProfileByEntity } from '../../Services/userService.js'
import FadeLoader from "react-spinners/FadeLoader";
import termsOfServicesData from '../TermsOfServices/TermsOfServices.json';
import termsOfUsesData from '../TermsOfServices/TermsOfUses.json'
import BootstrapDialog from '../BootstrapDialog/BootstrapDialog.jsx';
import BootstrapDialogpopup from './EmailConfirmationPopUpModel.jsx'
import { sendTemppassword } from '../../Services/userService.js'
import { FcCheckmark } from "react-icons/fc";
import { digitsWithAlphabetsRegex } from '../utils/regexPatterns'

const OwnerRegistration = (props) => {
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const loginURL = `${window.location.origin}/login`; // Dynamically build the login URL based on the environment
    const [loader, setLoader] = useState(false);
    const [dialogData, setDialogData] = useState({ title: '', sections: [] });
    const [open, setOpen] = useState(false);
    const [openpopup, setOpenmodel] = useState(false);
    const [EmailPopUp, setEmailPopUp] = useState(false);
    const [isemailVerified, setEmailverified] = useState(false);
    const [isphoneVerified, setPhoneVerified] = useState(false);
    const [PhoneVerification, setPhoneVerification] = useState(false);
    const [InValidOTP, setInValidOTP] = useState("");
    const [cityval, setCity] = useState();
    const [OTPValidation, setOTPValidation] = useState("");
    const [formData, setFormData] = useState({
        EntityName: '',
        EnityMailingAdderess: '',
        EnityMailingAdderess2: '',
        Country: 'United States',
        CityId: '',
        Zipcode: '',
        StateId: '',
        FirstName: '',
        LastName: '',
        Title: '',
        EmailId: '',
        MobileNumber: '',
        OfficeNumber: '',
        Password: 'Geoparking@123',
        TandC: false,
        ConfirmPassword: 'Geoparking@123',
        IsEmailPhoneVerified: false,
        TandC2: false
    });
    const [errors, setErrors] = useState({});
    const [suggestions, setSuggestions] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    // Fetch states on component mount
    useEffect(() => {
        const fetchStates = async () => {
            try {
                const response = await axios.get('https://api.countrystatecity.in/v1/countries/US/states', {
                    headers: {
                        "X-CSCAPI-KEY": "MVFIREJGVWNpT0cwTWdtTTQ4eVhkQlBRRXB3YTNMdHpzYjE4aVF0cQ==",
                    },
                });
                const sortedStates = response.data
                    .filter(state => extendedAlphaNumericRegex.test(state.iso2))
                    .sort((a, b) => a.name.localeCompare(b.name));
                setStates(sortedStates);
            } catch (error) {
                console.error("Error fetching states:", error);
            }
        };
        fetchStates();
    }, []);
    useEffect(() => {
        const fetchcityid = async (id) => {
            try {
                const cities = await fetchCities(id);
                // Handle the cities data here
            } catch (error) {
                console.error("Error fetching cities:", error);
            }
        };

        if (formData.StateId) {  // Ensure both FormData.StateId and data are available
            const stateid = findIso2Code(formData.StateId, states);
            if (stateid) {
                fetchcityid(stateid);
            } else {
                console.warn("State not found");
            }
        }
    }, [formData.StateId]);

    function findIso2Code(stateName, data) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].name.toLowerCase() === stateName.toLowerCase()) {
                return data[i].iso2;
            }
        }
        return null;  // Return null if not found
    }

    // Fetch cities based on state selection
    const fetchCities = async (stateId) => {
        try {
            const response = await axios.get(`https://api.countrystatecity.in/v1/countries/US/states/${stateId}/cities`, {
                headers: {
                    "X-CSCAPI-KEY": "MVFIREJGVWNpT0cwTWdtTTQ4eVhkQlBRRXB3YTNMdHpzYjE4aVF0cQ==",
                },
            });
            setCities(response.data);  // Update cities
            return response.data;
        } catch (error) {
            console.error("Error fetching cities:", error);
            return [];
        }
    };
    useEffect(() => {
        const fetchOwnerdetails = async () => {
            setLoader(true);  // Show loader before fetching data
            try {
                const ownerDetails = await fetchUserDetailsByEmailId(props.EmailIdOwner);
                setFormData({
                    EntityName: ownerDetails?.entityName || "",
                    EnityMailingAdderess: ownerDetails?.enityMailingAdderess || "",
                    EnityMailingAdderess2: ownerDetails?.enityMailingAdderess2 || "",
                    Country: ownerDetails?.country || "United States",
                    Zipcode: ownerDetails?.zipcode || "",
                    StateId: ownerDetails?.stateId || "",
                    EmailId: ownerDetails?.emailId,
                    FirstName: ownerDetails?.firstName || "",
                    LastName: ownerDetails?.lastName || "",
                    EmailId: ownerDetails?.emailId || "",
                    MobileNumber: ownerDetails?.mobileNumber || "",
                    OfficeNumber: ownerDetails?.officeNumber || "",
                    Title: ownerDetails.title,
                    CityId: ownerDetails?.cityId,
                    IsEmailPhoneVerified: ownerDetails?.isEmailPhoneVerified,
                    TandC: ownerDetails?.tandC,
                    TandC2: ownerDetails?.tandC
                });
                setPhoneVerified(ownerDetails?.isEmailPhoneVerified);
                setEmailverified(ownerDetails?.isEmailPhoneVerified);
            } catch (error) {
                console.error("Error fetching parking lot details:", error);
            } finally {
                setLoader(false);
            }
        };
        if (props.EmailIdOwner) {
            fetchOwnerdetails();
        }
    }, [props.EmailIdOwner]);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (name === "EntityName" && value.trim()) {
                if (/^\d+$/.test(value)) {
                    newErrors.EntityName = "Entity Name cannot contain only digits";
                } else if (!digitsWithAlphabetsRegex.test(value)) {
                    newErrors.EntityName = "Entity Name should contain letters and may contain digits";
                } else {
                    delete newErrors.EntityName;
                }
            }
            if (name === "EmailId" && ((/\S+@\S+\.\S+/.test(value)) || value === "" )) {
                delete newErrors.EmailId;
                setEmailverified(false);
            }

            if (name === "StateId" && value) {
                delete newErrors.StateId;
            }
            if (name === "CityId" && value) {
                delete newErrors.CityId;
            }
            if (name === "MobileNumber") {
                setPhoneVerified(false);
                delete newErrors.MobileNumber;
            }
            if (["FirstName", "LastName", "Title"].includes(name) && alphabetRegex.test(value)) {
                delete newErrors[name];
            }
            if (name === "Zipcode" && digitRegex.test(value) && value.length === 5) {
                delete newErrors.Zipcode;
            }
            return newErrors;
        });
    };



    const handleSearchChange = async (e) => {
        const { value } = e.target;
        setFormData((prev) => ({ ...prev, EnityMailingAdderess: value }));

        if (value.trim().length > 2) {
            try {
                const response = await axios.get(
                    `https://atlas.microsoft.com/search/address/json?&subscription-key=EN5vcaicwC78zKi3M3980VVB1m7DuuN0vKaDMtYdjBg7daKhx3OTJQQJ99AHAC8vTIngVrqvAAAgAZMPLtXG&api-version=1.0&language=en-US&query=${value}&countrySet=US`
                );
                const results = response.data.results || [];
                const addressSuggestions = results.map((result) => result.address);
                setSuggestions(addressSuggestions);
            } catch (error) {
                console.error("Error fetching address suggestions:", error);
            }
        } else {
            setSuggestions([]);
        }
    };

    // Handle address selection from suggestions
    const handleAddressSelect = async (address) => {
        try {
            // Find the selected state from the list of states
            const selectedState = states.find(
                (state) => state.name.toLowerCase() === address.countrySubdivisionName?.toLowerCase()
            );
            if (selectedState) {
                // Fetch cities for the selected state and then find the city
                const cityList = await fetchCities(selectedState.iso2);

                const selectedCity = cityList.find(
                    (city) => city.name.toLowerCase() === address.municipality?.toLowerCase()
                );

                setFormData((prevState) => ({
                    ...prevState,
                    CityId: selectedCity ? selectedCity.name : "",
                    StateId: selectedState?.name,
                    Zipcode: address.postalCode,
                    EnityMailingAdderess: address.freeformAddress?.split(',')[0]
                }));

                // Clear validation errors for CityId, StateId, Zipcode, and EnityMailingAdderess
                setErrors((prevErrors) => {
                    const newErrors = { ...prevErrors };

                    // Clear the errors if valid data is set
                    if (selectedCity) delete newErrors.CityId;
                    if (selectedState) delete newErrors.StateId;
                    if (address.postalCode) delete newErrors.Zipcode;
                    if (address.freeformAddress) delete newErrors.EnityMailingAdderess;

                    return newErrors;
                });
            } else {
                console.warn("No matching state found for the address:", address.countrySubdivisionName);
            }
        } catch (error) {
            console.error("Error in handleAddressSelect:", error);
        } finally {
            setSuggestions([]);
        }
    };

    const validateEmailandphone = async (emailid,phonenumber) => {
        const newErrors = {};
        const { EmailId, MobileNumber } = formData;
        if (phonenumber) {
            if (!MobileNumber) {
                newErrors.MobileNumber = "Mobile Number is required";
            } else {
                const phoneNumber = MobileNumber.replace(/\D/g, "");
                if (phoneNumber.length !== 11 || !phoneNumber.startsWith("1")) {
                    newErrors.MobileNumber = "Mobile number is invalid";
                }
            }
        } else if(emailid){  
            const emailExists = await checkEmailExistence(formData.EmailId);
            if (!EmailId || !/\S+@\S+\.\S+/.test(EmailId)) {
                newErrors.EmailId = "Valid Email is required";
            }
            else if (emailExists && !props?.EmailIdOwner) {
                newErrors.EmailId = "Email already exists";
            }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
        }
    const validateForm = async (sec1, sec2, sec3) => {
        const newErrors = {};
        const { EntityName, FirstName, LastName, EnityMailingAdderess, Title, EmailId, Zipcode, CityId, StateId, MobileNumber, OfficeNumber, TandC, TandC2 } = formData;
        if (sec1) {
            if (!MobileNumber) {
                newErrors.MobileNumber = "Mobile Number is required";
            } else {
                const phoneNumber = MobileNumber.replace(/\D/g, "");
                if (phoneNumber.length !== 11 || !phoneNumber.startsWith("1")) {
                    newErrors.MobileNumber = "Mobile number is invalid";
                }
            }
            if (!OfficeNumber) {
                // errors.OfficeNumber = "Mobile Number is required";
            } else {
                const officeNumber = formData.OfficeNumber.replace(/\D/g, "");
                if (officeNumber.length !== 11 || !officeNumber.startsWith("1")) {
                    newErrors.OfficeNumber = "Office Number is invalid";
                }
            }
            if (!FirstName || !alphabetRegex.test(FirstName)) newErrors.FirstName = "First Name: Required, alphabets only";
            if (!LastName || !alphabetRegex.test(LastName)) newErrors.LastName = "Last Name: Required, alphabets only";
            if (!Title || !alphabetRegex.test(Title)) newErrors.Title = "Title: Required, alphabets only";
            const emailExists = await checkEmailExistence(formData.EmailId);
            if (!EmailId || !/\S+@\S+\.\S+/.test(EmailId)) {
                newErrors.EmailId = "Valid Email is required";
            }
            else if (emailExists && !props?.EmailIdOwner) {
                newErrors.EmailId = "Email already exists";
            }
        }
        if (sec2) {
            if (!digitsWithAlphabetsRegex.test(EntityName)) newErrors.EntityName = "Entity Name cannot contain only digits";
            if (!EntityName) newErrors.EntityName = "Entity Name is required";
            if (!EnityMailingAdderess) newErrors.EnityMailingAdderess = "Entity MailingAdderess is required";

            if (!Zipcode || !digitRegex.test(Zipcode) || Zipcode.length !== 5) newErrors.Zipcode = "Valid 5-digit ZIP code is required";
            if (!StateId) newErrors.StateId = "State is required";
            if (!CityId) newErrors.CityId = "City is required";
        }
        if (sec3) {
            if (!TandC) newErrors.TandC = "Terms of Service is required";
            if (!TandC2) newErrors.TandC2 = "Terms of Uses is required";
            if (!isphoneVerified) {
                newErrors.MobileNumber = "Mobile verification not completed";
            }
            if (!isemailVerified) {
                newErrors.EmailId = "Email verification not completed";
            }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleSubmitEmail = async (e) => {
        e.preventDefault();
        try {
            const isValid = await validateEmailandphone(true, false);
            if (isValid) {
                setLoader(true);  // Set loader to true before making any request  
                // Wait for the response from sendOTPByEmail before proceeding
                await sendOTPByEmail();
            }
        } catch (error) {
            console.error('Error during form submission:', error);
        } finally {
            // Set loader to false after sendOTPByEmail completes (or an error occurs)
            setLoader(false);
        }
    };
    const handleSubmitPhone = async (e) => {
        e.preventDefault();
        try {
            const isValid = await validateEmailandphone(false, true);
            if (isValid) {
                setLoader(true);  // Set loader to true before making any request  


                // Wait for the response from sendOTPByEmail before proceeding
                await sendOTPByPhone(formData?.MobileNumber);
            }
        } catch (error) {
            console.error('Error during form submission:', error);
        } finally {
            // Set loader to false after sendOTPByEmail completes (or an error occurs)
            setLoader(false);
        }
    };

    const handleCheckboxChange2 = () => {
        setFormData({
            ...formData,
            TandC2: !formData.TandC2,
        });
        errors.TandC2 = "";
    };
    const handleCheckboxChange = () => {
        setFormData({
            ...formData,
            TandC: !formData.TandC,
        });
        errors.TandC = "";
    };
    const handleClickOpen = (type) => () => {
        let item;
        if (type === 'termsOfServices') {
            item = termsOfServicesData.termsOfServices[0];
        } else if (type === 'termsOfUses') {
            item = termsOfUsesData.termsOfUses[0];
        }
        if (item) {
            setDialogData(item);
            setOpen(true);
        } else {
            console.error("No data found.");
        }
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleCancel = () => {
        props.handleRegsiterOwner(false);
        setErrors({});
    };


    const sendOTPByEmail = async () => {
        let EmailId = {
            EmailId: formData.EmailId
        };
        setLoader(true);
        try {
            const requestOptions = {
                method: "POST",
                headers: {
                    "x-functions-key": hostHeader,
                },
                body: JSON.stringify(EmailId),
            };
            const response = await axios.post(
                `${hostPath}/api/SendOtpToEmail`,
                requestOptions.body,
                { headers: requestOptions.headers }
            );
            if (response?.data) {
                setLoader(false);
                setEmailPopUp(true)
                setOpenmodel(true)
                setInValidOTP("")
                setOTPValidation("")
            }
        } catch (error) {
            console.error("Error sending email:", error);
        }
        finally {
            setLoader(false);
        }
    };
    const sendOTPByPhone = async (MobileNumber) => {
        setLoader(true);
        try {
            const requestOptions = {
                method: "POST",
                headers: {
                    "x-functions-key": hostHeader,
                },
                body: JSON.stringify({ CellNumber: MobileNumber }),
            };
            const response = await axios.post(
                `${hostPath}/api/SendOtpToSMS`,
                requestOptions.body,
                { headers: requestOptions.headers }
            );
            if (response.data) {
                setLoader(false);
                setPhoneVerification(true)
                setEmailPopUp(false)
                setOpenmodel(true)
                setInValidOTP("")
                setOTPValidation("")
            }
        } catch (error) {
            console.error("Error sending SMS:", error);
        }
    };


    const handleVerify = async (otp, type) => {
        if (otp.length !== 5) {
            setOTPValidation("Please Enter 5 Digit OTP!")
        } else {
            setOTPValidation("")
            const OTP = Number(otp)
            if (EmailPopUp) {
                const requestOptions = {
                    method: "POST",
                    headers: {
                        "x-functions-key": hostHeader,
                    },
                    body: JSON.stringify({
                        EmailId: formData.EmailId,
                        OTP: OTP,
                    }),
                };

                const response = await axios.post(
                    `${hostPath}/api/VerifyOtp`,
                    requestOptions.body,
                    { headers: requestOptions.headers }
                );
                if (response.data.status === true) {
                    setInValidOTP("")
                    setEmailverified(true);
                    setOpenmodel(false);
                }
                else if (response.data.status === false) {
                    setInValidOTP(response.data.responseMessage)
                }
            } else {
                const requestOptions = {
                    method: "POST",
                    headers: {
                        "x-functions-key": hostHeader,
                    },
                    body: JSON.stringify({
                        CellNumber: formData.MobileNumber,
                        OTP: OTP,
                    }),
                };
                const response = await axios.post(
                    `${hostPath}/api/VerifySMSOtp`,
                    requestOptions.body,
                    { headers: requestOptions.headers }
                );
                //If OTP is verified successfully, redirect the user to the account info page
                if (response.data && response.data.status === true) {
                    setPhoneVerified(true);
                    setInValidOTP("")
                    setOpenmodel(false)
                }
                else if (response.data.status === false) {
                    setInValidOTP(response.data.responseMessage)
                }
            }
        }
    };
    const handleRegistrationSave = async () => {
        try {
            setLoader(true); // Start the loader
            // Send temporary password
            const isValid = await validateForm(true, true, false);
            if (isValid) {
                let updatedFormData = { ...formData, EmpId: props.empId, IsEmailPhoneVerified: isemailVerified && isphoneVerified };
                let entityRegistrationResponse;
                // Proceed with entity registration
                if(props.EmailIdOwner){
                    updatedFormData = { ...formData, EmpId: props.empId,EntityRegistrationID:props.entityIdOwner, IsEmailPhoneVerified: isemailVerified && isphoneVerified };
                    entityRegistrationResponse = await UpdateProfileByEntity(updatedFormData);
                }
                else {
                    entityRegistrationResponse = await entityRegistration(updatedFormData);
                }

                if (entityRegistrationResponse) {
                    props.handleRegsiterOwner(false);
                }
            }
        } catch (error) {
            console.error("Error in registration", error);
        } finally {
            setLoader(false); // Stop the loader regardless of success or error
        }
    };
    const handleRegistration = async () => {
        try {
            setLoader(true); // Start the loader
            // Send temporary password
            const isValid = await validateForm(true, true, true);
            const temppassword = {
                name: formData.FirstName,
                username: formData.EmailId,
                loginLink: loginURL
            };

            if (isValid) {
                const sendtemppassword = await sendTemppassword(temppassword);
                let updatedFormData = { ...formData, EmpId: props.empId, IsEmailPhoneVerified: isemailVerified && isphoneVerified,IsTempPassword:true };
               
                const password = {
                    EmailId: formData.EmailId,
                    Password: sendtemppassword
                };
                let entityRegistrationResponse;
                if (props.EmailIdOwner) {
                    if (sendtemppassword) {
                        updatedFormData = { ...formData, EmpId: props.empId,EntityRegistrationID:props.entityIdOwner, IsEmailPhoneVerified: isemailVerified && isphoneVerified,IsTempPassword:true };
                        entityRegistrationResponse = await UpdateProfileByEntity(updatedFormData);
                    }
                } else {
                    if (sendtemppassword) {
                        entityRegistrationResponse = await entityRegistration(updatedFormData);
                    }
                }
                if (entityRegistrationResponse) {
                    const updatePassword = await UpdatePassword(password);
                    if (updatePassword) {
                        props.handleRegsiterOwner(false);
                    }
                }
            }
        }
        catch (error) {
            console.error("Error in registration", error);
        } finally {
            setLoader(false); // Stop the loader regardless of success or error
        }
    };

    const handleResend = (type) => {
        // Logic to resend the OTP
    };
    const onClose = () => {
        setOpenmodel(false)
        setInValidOTP("")
        setOTPValidation("")

    }
    return (
        <div>
            {loader && (
                <div className="loader-overlay">
                    <FadeLoader />
                </div>
            )}
            {props.registerOwner && (
                <div className="OwnerRegistration-Container">
                    <h4>Owner Registration</h4>
                    <form >
                        <div className="section">
                            <h5>Personal Information</h5>
                            <div className="d-flex">
                                <InputField label="First Name" name="FirstName" value={formData.FirstName} onChange={handleInputChange} error={errors.FirstName} required />
                                <InputField label="Last Name" name="LastName" value={formData.LastName} onChange={handleInputChange} error={errors.LastName} required />
                                <InputField label="Title" name="Title" value={formData.Title} onChange={handleInputChange} error={errors.Title} required />
                                <div style={{ position: 'relative' }}>
                                    <InputField
                                        label="Email Address"
                                        name="EmailId"
                                        type="email"
                                        value={formData.EmailId}
                                        onChange={handleInputChange}
                                        error={errors.EmailId}
                                        required
                                        style={{
                                            width: `${Math.max(200, formData.EmailId.length * 10)}px`, // Adjust width based on input length
                                            paddingRight: '80px', // Space for verify button/checkmark
                                            transition: 'width 0.2s ease-in-out', // Smooth transition for width changes
                                        }}
                                    />
                                    {isemailVerified  ? (
                                        <FcCheckmark
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                right: '15px',
                                                transform: 'translateY(-50%)'
                                            }}
                                        />
                                    ) : (
                                        <button className='emailphoneverify-button'
                                            style={{
                                                top: '56%',
                                                right: '17px',
                                            }}
                                            onClick={handleSubmitEmail}
                                        >
                                            Verify
                                        </button>
                                    )}
                                </div>

                            </div>
                            <div className="d-flex">
                                <div style={{ position: 'relative' , minHeight: '60px'}}>
                                    <label htmlFor="MobileNumber" className="loginlabel formInputs">Mobile Phone
                                    <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                                    </label>
                                    <PhoneInput
                                        international
                                        countryCallingCodeEditable={false}
                                        name="MobileNumber"
                                        maxLength={15}
                                        countries={['US']}
                                        value={formData.MobileNumber}
                                        id="MobileNumber"
                                        placeholder="Enter Your Contact Number"
                                        defaultCountry="US"
                                        onChange={(value) => handleInputChange({ target: { name: 'MobileNumber', value } })}
                                        className="Phone-Internal-Input"
                                        style={{ paddingRight: '80px', width: '250px' }} // Add padding to make space for the button
                                    />
                                    {errors.MobileNumber && <div className="validation-error-owner">{errors.MobileNumber}</div>}

                                    {isphoneVerified ? (
                                        <FcCheckmark
                                            style={{
                                                position: 'absolute',
                                                top: '68%',
                                                right: '15px',
                                                transform: 'translateY(-50%)'
                                            }}
                                        />
                                    ) : (
                                        <button className='emailphoneverify-button'
                                            style={{
                                                top: '70%',
                                                right: '10px',
                                            }}
                                            onClick={handleSubmitPhone} // Add a handler for phone verification
                                        >
                                            Verify
                                        </button>
                                    )}
                                </div>

                                <div>
                                    <label htmlFor="OfficeNumber" className="loginlabel formInputs">Office Phone</label>
                                    <PhoneInput
                                        international
                                        countryCallingCodeEditable={false}
                                        name="OfficeNumber"
                                        maxLength={15}
                                        countries={['US']}
                                        value={formData.OfficeNumber}
                                        id="OfficeNumber"
                                        placeholder="Enter Office Contact Number"
                                        defaultCountry="US"
                                        onChange={(value) => handleInputChange({ target: { name: 'OfficeNumber', value } })}
                                        className="Phone-Internal-Input"
                                    />
                                    {errors.OfficeNumber && <div className="validation-error-owner">{errors.OfficeNumber}</div>}
                                </div>
                            </div>

                            {/* <div className="button-group">
                                <button type="submit" className="submit-button"
                                    onClick={(e) => handleSubmit(e)}
                                >Verify</button>
                            </div> */}
                        </div>
                        <div className="section">
                            <h5>Entity Information</h5>
                            <div className="d-flex">
                                <InputField label="Entity Name" name="EntityName" value={formData.EntityName} onChange={handleInputChange} error={errors.EntityName} required />
                               <div className="input-address"> 
                                <InputField
                                    label="Entity Mailing Address"
                                    name="EnityMailingAdderess"
                                    value={formData.EnityMailingAdderess}
                                    onChange={handleSearchChange}
                                    error={errors.EnityMailingAdderess}
                                    required
                                />
                                {suggestions.length > 0 && (
                                    <ul className="search-results-owner">
                                        {suggestions.map((result, index) => (
                                            <li key={index} onClick={() => handleAddressSelect(result)}>
                                                {result.freeformAddress}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                </div>

                                <InputField label="Mailing Address 2" name="EnityMailingAdderess2" value={formData.EnityMailingAdderess2} onChange={handleInputChange} />
                            </div>
                            <div className="d-flex">
                                <InputField label="ZIP Code" name="Zipcode" type="number" value={formData.Zipcode} onChange={handleInputChange} error={errors.Zipcode} required />
                                <DropdownField label="City" name="CityId" value={formData.CityId || cityval} options={cities} onChange={handleInputChange} error={errors.CityId} required />
                                <DropdownField label="State" name="StateId" value={formData.StateId} options={states} onChange={handleInputChange} error={errors.StateId} required />
                            </div>
                            <div className="d-flex   justify-content-start">
                                <div className="d-flex mb-4  ">
                                    <InputField
                                        label="Country"
                                        name="Country"
                                        type="text"
                                        value={formData.Country}
                                        readOnly
                                        required
                                        className="country"
                                    />
                                </div>

                                <div className="d-flex flex-column justify-content-between mb-2 ">
                                    <div className="remembercheckbox text-center">
                                        <label className="control control--checkbox mb-0">
                                            <input type="checkbox" checked={formData.TandC2} onChange={handleCheckboxChange2} disabled={!isemailVerified || !isphoneVerified}/>
                                            <span className=""> I Agree to<a className="joingeo" onClick={handleClickOpen("termsOfUses")}> Terms of Use </a></span>
                                            <div className="control__indicator"></div>
                                        </label>
                                        {errors.TandC2 && (<div className="error-message mandatorymark">{errors.TandC2}</div>)}
                                    </div>
                                </div>

                                <div className="d-flex flex-column justify-content-between mb-5 align-items-center">
                                    <div className="remembercheckbox text-center">
                                        <label className="control control--checkbox mb-0">
                                            <input
                                                type="checkbox"
                                                checked={formData.TandC}
                                                onChange={handleCheckboxChange}
                                                disabled={!isemailVerified || !isphoneVerified}
                                            />
                                            <span className=""> I Agree to <a className="joingeo" onClick={handleClickOpen("termsOfServices")} > Terms of Service </a> </span>
                                            <div className="control__indicator"></div>
                                        </label>
                                        {errors.TandC && (<div className="error-message mandatorymark">{errors.TandC}</div>)}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <BootstrapDialog
                            open={open}
                            onClose={handleClose}
                            title={`${dialogData.title}`}
                            content={dialogData.sections}
                            lastModified={`${dialogData.lastModified}`}
                            setOpen={setOpen}
                        />
                        {
                            < BootstrapDialogpopup
                                EmailPopUp={EmailPopUp}
                                open={openpopup}
                                initialEmail={EmailPopUp ? formData.EmailId : formData.MobileNumber}
                                destinationType={EmailPopUp ? "Email" : "MobileNumber"}
                                onVerify={handleVerify}
                                onResend={handleResend}
                                onClose={onClose}
                                InValidOTP={InValidOTP}
                                OTPValidation={OTPValidation}
                                sendOTPByEmail={sendOTPByEmail}
                                sendOTPByPhone={sendOTPByPhone} />
                        }

                        <div className="button-group">
                            <button type="button" className="cancel-button" onClick={handleCancel}>Cancel</button>
                            <button
                                type="button"
                                className={`submit-button ${!isphoneVerified && !isemailVerified ? 'enabled' : 'disabled'}`}
                                title={!(isphoneVerified && isemailVerified) ? 'Email and phone verification needs to be done' : ''}
                                onClick={() => { handleRegistrationSave() }}
                            >
                                Save
                            </button>
                            <button
                                type="button"
                                className={`submit-button ${formData.TandC && formData.TandC2? 'enabled' : 'disabled'}`}
                                title={!(isphoneVerified && isemailVerified) ? 'Email and phone verification needs to be done' : ''}
                                onClick={() => { handleRegistration() }}
                            >
                                Register
                            </button>

                        </div>
                    </form>

                </div>
            )}
        </div>
    );
};

// Reusable Input Field Component
const InputField = ({ label, name, value, onChange, error, type = "text", required }) => (
    <div className={`form-field mb-4 ${name}`}>
        <label htmlFor={name} className="loginlabel">
            {label} {required && <span className="mandatorymark">*</span>}
        </label>
        <input type={type} name={name} id={name} value={value} onChange={onChange} placeholder={`Enter ${label}`} />
        {error && <div className="validation-error-owner">{error}</div>}
    </div>
);

// Reusable Dropdown Field Component
const DropdownField = ({ label, name, value, options, onChange, error, required }) => (
    <div className={`form-field mb-4 ${name}`}>
        <label htmlFor={name} className="loginlabel">
            {label} {required && <span className="mandatorymark">*</span>}
        </label>
        <select name={name} id={name} value={value} onChange={onChange} className="form-control">
            <option value="">Select {label}</option>
            {options.map((option) => (
                <option key={option.iso2 || option.id} value={option.name}>
                    {option.name}
                </option>
            ))}
        </select>
        {error && <div className="validation-error-owner">{error}</div>}
    </div>
);

export default OwnerRegistration;
