import axios from 'axios';
const hostPath = process.env.REACT_APP_API_BASE_URL;
const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
export const fetchParkinglotDetailsByEmpId = async (EmpId) => {
    try {
        const response = await fetch(`${hostPath}/api/ParkingLotBookingDetailsbyEmpId`, {
            method: 'POST',
            headers: { "x-functions-key": `${hostHeader}` },
            body: JSON.stringify({ EmpId }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;  // Return the fetched user data
    } catch (error) {
        console.error('Failed to fetch user details:', error);
        throw error;  // Re-throw the error to handle it in the component
    }
};
export const fetchParkinglotDetailsbyentityid = async (EntityRegistrationID ) => {
    try {
        const response = await fetch(`${hostPath}/api/ParkingLotBookingDetailsbyId`, {
            method: 'POST',
            headers: { "x-functions-key": `${hostHeader}` },
            body: JSON.stringify({ EntityRegistrationID  }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;  // Return the fetched user data
    } catch (error) {
        console.error('Failed to fetch user details:', error);
        throw error;  // Re-throw the error to handle it in the component
    } 
};