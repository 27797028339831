import React, { useEffect, useState, useRef } from 'react';
import DataTable from "react-data-table-component";
import axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import MapDashboard from '../myproporties/MapDashboard';
import FadeLoader from "react-spinners/FadeLoader";
import { BiSortAlt2 } from "react-icons/bi";
import './VerifyAlert.css'
import { getLoggedInUser, fetchGetInternalEmployeeDetailsByEmailId } from '../../Services/employeeService.jsx';

const ApprovedProperties = (props) => {
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const [parkingLotBookingDetails, setParkingLotBookingDetails] = useState(props.parkingLotBookingDetails ||[]);
    const [filter, setFilter] = useState([]);
    const [search, setSearch] = useState("");
    const [open, setOpen] = React.useState(false);
    const [rowData, setRowData] = useState([]);
    const [areasmarked, setAreasmarked] = useState([]);
    const [isEdit, setEdit] = useState();
    const [parkinglotObj, setparkinglotObj] = useState();
    const [defaultaddress, setDefaultaddress] = useState();
    const [loader, setLoader] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(''); // This will store the selected status
    const [sortDirection, setSortDirection] = useState('asc'); // State for sorting direction
    const [isSalespersonloggedin, setSalespersonloggedin] = useState(true);
    const [myworklistredirect, setMyworklistredirect] = useState(true);
    const [verifiedBy, setVerfiedBy] = useState();
    const handleSort = () => {
        // Toggle the sorting direction
        const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        setSortDirection(newSortDirection);

        // Sort the data based on the 'createdDate' field
        const sortedData = [...filter].sort((a, b) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);

            if (newSortDirection === 'asc') {
                return dateA - dateB;
            } else {
                return dateB - dateA;
            }
        });

        setFilter(sortedData);
    };
    const stateLogedInUser = getLoggedInUser('companyEmail');
    const fetchVerifiedBy = async () => {
        try {
            const verifiedby = await fetchGetInternalEmployeeDetailsByEmailId(stateLogedInUser);
            setVerfiedBy(verifiedby.firstName);
        } catch (error) {
            console.error("Error fetching parking lot details:", error);
        }
    };
    useEffect(() => {
        fetchVerifiedBy();
    }, [stateLogedInUser])
    useEffect(() => {
        let result = parkingLotBookingDetails;
        // Filter by search query
        if (search) {
            result = result.filter((item) => {
                return (item.parkingLotAddress || item.createdDate).toLowerCase().includes(search.toLowerCase());
            });
        }
        if (selectedStatus.length > 0) {
            result = result.filter((item) => selectedStatus.includes(item.status));
        }
        setFilter(result);
    }, [search, parkingLotBookingDetails, selectedStatus]);
    const handleConfirm = (text) => {
        if (text == 'confirm') {
            const requestOptions = {
                method: 'POST',
                headers: { "x-functions-key": hostHeader },
                body: JSON.stringify(rowData)
            };
            axios.post(`${hostPath}/api/DeleteParkingLotDetailsByEmailId`, requestOptions.body, { headers: requestOptions.headers })
                .then(response => {
                    if (response.data) {
                        if (response.data.status) {
                            const newdata = parkingLotBookingDetails.filter((item) => item.parking_Lot_ID !== rowData.Parking_Lot_ID);
                            setParkingLotBookingDetails(newdata);
                            setFilter(newdata);
                            setOpen(false);
                        }
                        else {
                            alert(response.data.responseMessage);
                        }
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
        else if (text == 'cancel') {
            setOpen(false);
        }
    };
    const columns = [
        {
            name: "Nickname",
            selector: (row) => row.streetName,
            style: {
                minWidth: '200px', // Increase the minimum width of the column
            },
        },
        {
            name: "Street Address",
            selector: (row) => row.parkingLotAddress,
            style: {
                minWidth: '250px', // Increase the minimum width of the column
            },
        },
        {
            name: "Status",
            selector: (row) => row?.status,
        },

        {
            name: "Created By",
            selector: (row) => row?.createdBy || 'NA',
        },
        {
            name: "Approved By",
            selector: (row) => row?.approvedBy|| 'NA',
        },
        {
            name: (
                <div style={{ display: 'flex', alignItems: 'center' }} onClick={handleSort}>
                    Last Updated <BiSortAlt2 style={{ marginLeft: '5px' }} />
                </div>
            ),
            selector: (row) => row.createdDate,
        },
        {
            name: "Comments",
            selector: (row) => row.comment || 'NA',
        },
    ];

    const tableHeaderStyle = {
        headCells: {
            style: {
                height: "90px",
                fontWeight: 800,
                fontSize: "16px",
                backgroundColor: "white",
                lineHeight: "19.2px",
                fontFamily: "Lato",
                textAlign: "left",
                borderBottom: "none",
            },
        },
        cells: {
            style: {
                minWidth: '200px', // Ensure all cells have a minimum width
            },
        },
    };
    return (
        <div>
            {loader && (
                <div className="loader-overlay">
                    <FadeLoader />
                </div>
            )}
            

            {!isEdit ? (
                <div>
                    <div className='container-fluid p-0'>
                        <div className="lotmanagement" style={{ margin: "0px" }}>
                            <React.Fragment>
                                <DataTable
                                    customStyles={{
                                        headCells: tableHeaderStyle.headCells,
                                        cells: {
                                            style: {
                                                fontFamily: 'Lato',
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                lineHeight: '18.6px',
                                                textAlign: 'left',
                                                height: "60px",
                                                borderBottom: "none"
                                            },
                                        },
                                    }}
                                    columns={columns}
                                    data={filter.length ? filter : []}  // Pass empty array to prevent row rendering
                                    pagination
                                    fixedHeader
                                    selectableRowsHighlight
                                    highlightOnHover
                                    persistTableHead
                                    noDataComponent={
                                        <div style={{ padding: '20px 0', textAlign: 'center' }}>
                                            No records found
                                        </div>
                                    } />
                            </React.Fragment>
                        </div>
                    </div>
                    <Dialog
                        classes={{ paper: 'custom-paper-class-delete' }}
                        aria-labelledby="customized-dialog-title"
                        dividers
                        open={open}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <DialogTitle dividers sx={{ textAlign: 'start', color: "#000;", fontSize: "24px", fontWeight: "700", padding: "5px 24px " }} id="customized-dialog-title">
                            Are you Sure You Want to Delete This Property?
                        </DialogTitle>
                        <DialogContent style={{ border: "none", padding: "5px 22px", color: "#000;" }}>
                            <Typography gutterBottom>
                                <span>Once you delete this property, you will not be able to retrieve the data. This action is irreversible. Please confirm if you wish to proceed.</span>
                            </Typography>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: 'right' }}>
                            <Button
                                className='custom-button-cancel'
                                onClick={() => handleConfirm('cancel')}
                            >
                                Cancel
                            </Button>
                            <Button
                                className='custom-button-delete'
                                onClick={() => handleConfirm('confirm')}
                            >
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>

                </div>
            ) : (
                <div><MapDashboard isEdit={isEdit} areasMarked={areasmarked} defaultaddress={defaultaddress} parkinglotObj={parkinglotObj} isSalespersonloggedin={isSalespersonloggedin} myworklistredirect={myworklistredirect} verifiedBy={verifiedBy}/></div>
            )}
        </div>
    );
};

export default ApprovedProperties;
