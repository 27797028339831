export const Departments = [
    { "id": "1", "name": "Inside Sales" },
    { "id": "2", "name": "Outside Sales" },
    { "id": "3", "name": "Admin" },
    { "id": "4", "name": "CXO" }
  ];
  export const options = [
    'Submitted',
    'Active',
    'Inactive',
    'Rejected',
    'Draft'
  ];