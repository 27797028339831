import MyPropertiesHome from './myProperties_Home';
import MapDashboard from './MapDashboard';
const LocatePropertNewScreen = ({handleMapvisible,mapvisible}) => {
	return (
		<div>
			{!mapvisible ? (
			<MyPropertiesHome handleMapvisible={handleMapvisible}/>
			)
			:(
			 <MapDashboard/>
			)
		}
		</div> 
	);
};

export default LocatePropertNewScreen;