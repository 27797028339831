import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import atlas from 'azure-maps-control';
import axios from 'axios';
import { MdArrowBackIosNew } from "react-icons/md";
import { FaRegSave } from "react-icons/fa";
import { fetchUserDetails } from '../../Services/userService.js'
import VerifyAlert from '../MyWorklist/VerifyAlert';
import FadeLoader from "react-spinners/FadeLoader";

const ReviewAndConfirm = (props) => {
    const { overrides, handleReview, areasMarked, addressvalue, accountinfo, EmailId, latitude, longitude, toggleCondition, handleviewparking, defaultaddress, timeValues, selectedValue, streetName, isSalespersonloggedin, myworklistredirect, isautoSave, parkinglotId, events, userData, selecetedAddress, ...rest } = props;
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const [searchedLocation, setsearchedLocation] = useState('')
    const [City, setCity] = useState('')
    const [State, setState] = useState('')
    const [ZipCode, setZip] = useState('')
    const [BankDetails, setBankDetails] = useState({})
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    var map;
    useEffect(() => {
        (addressvalue.length !== 0) ? setsearchedLocation(addressvalue) : setsearchedLocation(defaultaddress.trim());
        setBankDetails(accountinfo);
        if (addressvalue.length !== 0) {
            const address = selecetedAddress.find((item) => item.freeformAddress === addressvalue);
            setCity(address.municipality);
            setState(address.countrySubdivisionName);
            setZip(address.postalCode);
        } else {
            setCity(userData?.cityId);
            setState(userData?.stateId);
            setZip(userData?.zipcode);
        }

    }, [accountinfo, defaultaddress]);
    useEffect(() => {
        MapDetails();
    }, []);
    // Helper function to get color based on text and determine the text color (black or white)
    const getColorAndTextColor = (text) => {
        const colorMap = {
            "PSL - Parking Space Location": "#006400", // Dark Green
            "PKE - Parking Entrance": "#32CD32",       // Medium Green
            "PKX - Parking Exit": "#98FB98",           // Light Green
            "PUE - Pick Up Entrance": "#FFD700",       // Bright Yellow
            "PUL - Pick Up Location": "#FFC300",       // Medium Yellow
            "PUX - Pick Up Exit": "#FFFACD",           // Pale Yellow
            "DOE - Drop Off Entrance": "#00008B",      // Dark Blue
            "DOL - Drop Off Location": "#1E90FF",      // Medium Blue
            "DOX - Drop Off Exit": "#ADD8E6",          // Light Blue
        };

        const backgroundColor = colorMap[text] || "#000000"; // Default to black if text not found

        // Convert hex color to RGB and calculate luminance
        const rgb = parseInt(backgroundColor.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = (rgb >> 0) & 0xff;

        const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
        const textColor = luminance > 140 ? "black" : "white"; // Use black for light colors and white for dark colors

        return { backgroundColor, textColor };
    };

    function MapDetails() {
        map = new atlas.Map('myMap', {
            center: [longitude || -122.18402494821507, latitude || 47.61373420362662],
            zoom: 18,
            language: 'en-US',
            style: 'satellite',
            view: 'Auto',
            authOptions: {
                authType: 'subscriptionKey',
                subscriptionKey: 'iKSTDEFy2eA9QfhSETwDwwnQoR_36iCV5wv18ubSOBw'
            }
        });

        const zoomControl = new atlas.control.ZoomControl();
        map.controls.add(zoomControl, { position: 'bottom-right' });

        var myCustomJson = areasMarked;
        map.events.add('ready', function () {
            if (myCustomJson.length > 0) {
                for (var i = 0; i < myCustomJson.length; i++) {
                    const position = new atlas.data.Position(myCustomJson[i]?.Coordinates?.Longitude, myCustomJson[i]?.Coordinates?.Latitude);
                    const nameSubstring = myCustomJson[i]?.Name?.substring(0, 3);

                    const { backgroundColor, textColor } = getColorAndTextColor(myCustomJson[i]?.Name);

                    // Create custom HTML content for each marker
                    const markerHTML = `
                    <div style="position: relative; width: 35px; height: 60px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="60" viewBox="0 0 24 36" fill="${backgroundColor}">
                            <path d="M12 0C5.383 0 0 5.383 0 12c0 7.188 10.83 22.812 10.83 22.812C11.147 34.81 12 34.365 12 34.365S12.853 34.81 13.17 34.812C13.17 34.812 24 19.188 24 12C24 5.383 18.617 0 12 0z"/>
                            <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="${textColor}" font-size="8">${nameSubstring}</text>
                        </svg>
                    </div>`;

                    // Create a new HTML marker with the custom HTML content
                    const marker = new atlas.HtmlMarker({
                        htmlContent: markerHTML,
                        position: position,
                        anchor: 'center'
                    });

                    // Add the marker to the map
                    map.markers.add(marker);
                }
            }
        });
    }


    const backButtonClick = () => {
        handleReview(false);
        props.backToBankdetails()
        toggleCondition('4 of 5 - Enter Bank Details')
        toggleCondition('5 of 5 - Review and Confirm')
    }
    const CoardinateDetails = areasMarked.map(item => ({
        Latitude: item.Coordinates.Latitude.toString(),
        Longitude: item.Coordinates.Longitude.toString(),
        Title: item.Coordinates.Title,
        ID: item.id.toString()
    }));
    const Scheduleavailability = events;
    const handleButtonClick = async (e) => {
        e.preventDefault();
        setIsButtonDisabled(true);
        const createdBy = await fetchUserDetails(props?.entityId);
        const email1 = {
            EmailId,
            Status: props.empId ? "Active" : "Submitted",
            StreetName: streetName,
            EntityRegistrationID: props.entityId,
            ParkingLotAddress: searchedLocation,
            EmpId: props.empId,
            CreatedBy: props.empId ? props?.verifiedBy : createdBy?.firstName,
            BankDetails,
            CoardinateDetails,
            Scheduleavailability,
            isParkingSpaceSelected: props.onlyPark,
            City: City,
            State: State,
            Zipcode: ZipCode,
            PricingPlanID: 2,
            BankDetailID: 2

        };
        const { AccountHolderName, AccountNumber, AccountType, BankName, RoutingNumber } = BankDetails;
        const isBankDetailsEmpty =
            AccountHolderName &&
            AccountNumber &&
            AccountType &&
            BankName &&
            RoutingNumber;
        const updateBankDetails = () => {
            BankDetails.EntityRegistrationID = props.entityId;
            return axios.post(`${hostPath}/api/UpdateBankDetails`, BankDetails, {
                headers: { "x-functions-key": hostHeader }
            });
        };
        const navigateAfterResponse = (response) => {
            if (response.data) {
                setLoader(false);
                if (myworklistredirect) {
                    navigate('/reviewtab');
                } else {
                    if (props.empId) navigate('/OwnerRegistationDashboard');
                    else navigate('/viewparkinginfo');
                }
                window.location.reload();
            }
        };

        const handleError = (error) => {
            console.error('Error:', error);
        };

        if (props.parkinglotObj) {
            setLoader(true);
            let status = (props.parkinglotObj.status === 'Draft') && (!props.empId) ? 'Submitted' : props.parkinglotObj.status;
            if (props.parkinglotObj.status === 'Draft' && props.empId) {
                status = 'Active';
            }
            const parkinglot = {
                EmailId: props.parkinglotObj.emailId,
                EntityRegistrationID: props.parkinglotObj.entityRegistrationID,
                ParkingLotAddress: searchedLocation,
                Parking_Lot_ID: props.parkinglotObj.parking_Lot_ID,
                id: props.parkinglotObj.id,
                CreatedDate: props.parkinglotObj.createdDate,
                CreatedBy: props?.verifiedBy,
                Status: status,
                Comment: props.parkinglotObj.comment,
                StreetName: streetName,
                EmpId: props.empId,
                BankDetails,
                CoardinateDetails,
                Scheduleavailability,
                isParkingSpaceSelected: props.onlyPark,
                City: City,
                State: State,
                Zipcode: ZipCode,
                PricingPlanID: 2,
                BankDetailID: 2
            };

            axios.post(`${hostPath}/api/UpdateParkingLotDetails`, parkinglot, {
                headers: { "x-functions-key": hostHeader }
            })
                .then((response) => {
                    
                    if (isBankDetailsEmpty) {
                        return updateBankDetails();
                    }
                    navigateAfterResponse(response);
                })
                .then(navigateAfterResponse)
                .catch(handleError);

        } else if (isautoSave && parkinglotId) {
            const reqbody = {
                ...email1,
                Parking_Lot_ID: parkinglotId.parkingLotId,
                id: parkinglotId.id,
                CreatedDate: parkinglotId.createdDate
            };

            axios.post(`${hostPath}/api/UpdateParkingLotDetails`, reqbody, {
                headers: { "x-functions-key": hostHeader }
            })
                .then((response) => {
                    if (isBankDetailsEmpty) {
                        return updateBankDetails();
                    }
                    navigateAfterResponse(response);

                })
                .then(navigateAfterResponse)
                .catch(handleError);

        } else {
            axios.post(`${hostPath}/api/ParkingLotBooking`, email1, {
                headers: { "x-functions-key": hostHeader }
            })
                .then((response) => {
                    if (isBankDetailsEmpty) {
                        return updateBankDetails();
                    }
                    navigateAfterResponse(response);

                })
                .then(navigateAfterResponse)
                .catch(handleError);
        }
    };


    const handleShouldapprove = (val1, val2) => {
        setPopupVisible(val2);
    }
    return (
        <div className="col-md-12 ">
            <div class="row m-4">
                <div className="col-lg-12 col-md-12 col-sm-12" style={{ textAlign: "left" }}>
                    <div><b>Review Marked Areas</b></div>
                    <div>Take a close look at the areas you've marked on the map to ensure they accurately reflect your intended designations.</div>
                </div>
            </div>
            <div class="row m-4"  >

                <div id="reviewAndConfirm" style={{}}>
                    <div class="row" style={{ textAlign: "left" }}>
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div> <b>Street Address : {searchedLocation}</b></div>
                        </div>
                    </div>
                    <div class="row "  >
                        <div class="col-lg-12 col-md-12 col-sm-12  m-3">
                            <div>
                                <div id="myMap" style={{ height: '750px', width: '100%', margin: '0% 0% 1% -1%' }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loader && (
                <div className="loader-overlay">
                    <FadeLoader />
                </div>
            )}
            {isSalespersonloggedin ? (
                <>
                    <div className=" mb-3" style={{ textAlign: "end", position: "relative" }}>
                        <button
                            type="button"
                            className='icon-btn'
                            onClick={backButtonClick}
                            style={{ marginRight: "10px", position: "relative", right: "65px", bottom: "217px" }}>
                            <MdArrowBackIosNew size={24} style={{ marginLeft: '-9px' }} title="Backward" />
                        </button>
                        <button
                            type="button"
                            className='icon-btn'
                            onClick={handleButtonClick}
                            style={{
                                marginRight: "10px", position: "relative", right: "70px", bottom: "217px", width: "80px", background: "#3f8ed7",
                                color: "white", fontWeight: "bold"
                            }}>
                            Save
                        </button>
                        <button
                            type="button"
                            className='icon-btn-finish'
                            onClick={() => handleShouldapprove(true, true)}
                            style={{ marginRight: "10px", position: "relative", right: "75px", bottom: "217px" }}>
                            Verify
                        </button>
                    </div>
                </>
            ) : (
                <div className=" mb-3" style={{ textAlign: "end", position: "relative" }}>
                    <button type="submit" className='finishbtn' onClick={backButtonClick} style={{ marginRight: "10px", position: "relative", right: "80px", bottom: "217px" }}>Go Back</button>
                    <button type="submit" className='nextBtn' onClick={handleButtonClick} disabled={isButtonDisabled} style={{ marginRight: "10px", position: "relative", right: "76px", bottom: "217px" }}>
                        {props.verificationstatus ? 'Save' : 'Confirm'}
                    </button>
                </div>
            )}
            {isPopupVisible && (
                <VerifyAlert handleShouldapprove={handleShouldapprove} isPopupVisible={isPopupVisible} BankDetails={BankDetails} Scheduleavailability={Scheduleavailability} CoardinateDetails={CoardinateDetails}
                    parkinglotObj={props.parkinglotObj} searchedLocation={searchedLocation} streetName={streetName} entityId={props.entityId} verifiedby={props.verifiedBy}
                />
            )}
        </div>
    )
}
export default ReviewAndConfirm;