
// EditChoiceModal.jsx
import React from 'react';
import './EditChoiceModal.css';
// Replace with your close icon path

const EditChoiceModal = ({ show, onClose, onEditChoice }) => {
  if (!show) return null;

  return (
    <div className="ecm-modal-overlay">
      <div className="ecm-modal">
        <div className="ecm-modal-header">
          <div className="ecm-modal-title-wrapper">
            <div>
              <h2 className="ecm-modal-title">Edit Recurring Event</h2>
              <p className="ecm-modal-subtitle">
                Do you want to edit this occurrence or the entire series?
              </p>
            </div>
          </div>
          <button onClick={onClose} className="ecm-close-button">
            ×
          </button>
        </div>
        <div className="ecm-modal-body">
          <button
            className="aval-custom-button aval-custom-button-delete"
            onClick={() => onEditChoice('single')}
          >
            Edit Occurrence
          </button>
          <button
            className="aval-custom-button aval-custom-button-delete"
            onClick={() => onEditChoice('series')}
          >
            Edit Series
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditChoiceModal;
