import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import './Navbar.css';
import logo from '../../Images/GeoParkingLogo.png'; // Adjust the path as necessary
import LogoutIcon from '@mui/icons-material/Logout';

const Navbar = ({ mapvisible, userData }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [showPopup, setShowPopup] = useState(false);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible);
    const [initial, setInitials] = useState(JSON.parse(localStorage.getItem('initials')));
    const [internalEmployeeEmail, setInternalEmployeeEmail] = useState('');

    useEffect(() => {
        const storedEmail = JSON.parse(localStorage.getItem('companyEmail'));
        setInternalEmployeeEmail(storedEmail);
    }, []);

    const handleMyPropertiesClick = (e) => {
        if (mapvisible) {
            e.preventDefault();
            setShowPopup(true);
        } else {
            navigate('/viewparkinginfo');
        }
    };

    const handleConfirmNavigate = () => {
        setShowPopup(false);
        navigate('/viewparkinginfo');
    };

    const handleCancelNavigate = () => {
        setShowPopup(false);
    };

    return (
        <nav className="navbar">
            {/* Navbar logo and links */}
            <div className="navbar-logo">
                <img src={logo} alt="Logo" />
            </div>
            <div className="navbar-links">
                <NavLink
                    to="/dashboard"
                    className={({ isActive }) => (isActive ? 'selected' : '')}
                >
                    Dashboard
                </NavLink>
                {!internalEmployeeEmail?.includes('@geoparking.com') ? (
                    <>
                        <NavLink
                            to="/viewparkinginfo"
                            className={({ isActive }) => (isActive ? 'selected' : '')}
                            onClick={handleMyPropertiesClick}
                        >
                            My Properties
                        </NavLink>
                        <NavLink to="/earnings" className={({ isActive }) => (isActive ? 'selected' : '')}>
                            Earnings
                        </NavLink>
                    </>
                ) : (
                    <>
                        <NavLink
                            to="/reviewtab"
                            className={({ isActive }) => (isActive ? 'selected' : '')}
                        >
                            Pending Approval
                        </NavLink>
                        <NavLink
                            to="/OwnerRegistationDashboard"
                            className={({ isActive }) => (isActive ? 'selected' : '')}
                        >
                            Owner & Property Management
                        </NavLink>
                        <NavLink
                            to="/GlobalSearchDashBoard"
                            className={({ isActive }) => (isActive ? 'selected' : '')}
                        >
                            Global Search
                        </NavLink>
                    </>
                )}
            </div>

            <div className="navbar-profile" onClick={toggleDropdown}>
                <div className="profile-icon">{initial}</div>
                {isDropdownVisible && (
                    <div className="dropdown-menu">
                        <ul>
                            {internalEmployeeEmail?.includes('@geoparking.com') ? (
                                <li>
                                    <a href="/" onClick={(e) => {
                                        e.preventDefault(); 
                                        localStorage.clear(); 
                                        window.location.href = '/'; 
                                    }}>
                                        Logout
                                    </a>

                                </li>
                            ) : (
                                <>
                                    <li>
                                        <a href="/settings/entity" onClick={() => window.location.href = '/settings/entity'}>
                                            Entity Information
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/settings/profile" onClick={() => window.location.href = '/settings/profile'}>
                                            Profile Information
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/settings/password" onClick={() => window.location.href = '/settings/change-password'}>
                                            Change Password
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/" onClick={(e) => {
                                            e.preventDefault(); 
                                            localStorage.clear(); 
                                            window.location.href = '/'; 
                                        }}>
                                            Logout
                                        </a>

                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                )}
            </div>
            {showPopup && (
                <div className="custom-popup">
                    <div className="popup-text">
                        <p>All unsaved progress will be lost. Are you sure you want to continue?</p>
                        <div className="popup-buttons">
                            <button className="confirm-btn" onClick={handleConfirmNavigate}>
                                Continue
                            </button>
                            <button className="cancel-btn" onClick={handleCancelNavigate}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </nav>
    );
};

export default Navbar;