import React, { useEffect, useState, useRef } from 'react';
import DataTable from "react-data-table-component";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import Navbar from '../NavBar/Navbar';
import { useNavigate } from 'react-router-dom';
import MapDashboard from '../myproporties/MapDashboard';
import FadeLoader from "react-spinners/FadeLoader";
import { IoEyeSharp } from "react-icons/io5";
import { BiSortAlt2 } from "react-icons/bi";
import StatusDropdown from '../MyWorklist/StatusDropdown'
import OwnerRegistration from './OwnerRegistration';
import { fetchUserDetailsByEmpId,fetchUsersWithValidEmpId, deleteUserByEmail } from '../../Services/userService.js'
import { getLoggedInUser ,fetchGetInternalEmployeeDetailsByEmailId} from '../../Services/employeeService.jsx';
import OwnerDetailsComponent from './OwnerDetailsComponent.jsx';
import PropertyList from '../PendingApprovals/PropertyList.jsx';
import OwnerRegistartionCard from './OwnerRegistrationList.jsx';
import { RxDotsVertical } from 'react-icons/rx'; // Import the dots icon


const OwnerRegistationDashboard = (props) => {
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const location = useLocation();
    const [userDetails, setUserdetails] = useState([]);
    const [filter, setFilter] = useState([]);
    const [search, setSearch] = useState("");
    const [popupVisible, setPopupVisible] = useState(false);
    const actionRef = useRef(null);
    const [open, setOpen] = React.useState(false);
    const [userData, setUserdata] = useState();
    const [isEdit, setEdit] = useState(false);
    const [defaultaddress, setDefaultaddress] = useState();
    const [loader, setLoader] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(''); // This will store the selected status
    const [sortDirection, setSortDirection] = useState('asc'); // State for sorting direction
    const [isSalespersonloggedin, setSalespersonloggedin] = useState(true);
    const [registerOwner, setRegisterOwner] = useState(false);
    const [fetchUsers, setFetchusers] = useState(false);  // State to track if a user was deleted
    const [selectedRowEmailID, setSelectedRow] = useState();
    const [selectedOwnerRow, setSelectedOnerDetails] = useState(null);
    const [OwnerDetails, setOwnerDetails] = useState(false);
    const[EmailIdOwner,setEmailId] = useState();
    const[entityIdOwner,setEntityIdowner] = useState();
    const [propertyOwner, setpropertyOwner] = useState(true);
    const [salespersonloggedin, setSalesperson] = useState();

    // Function to handle status change from StatusDropdown
    const handleUserdetails = (val) => {
        setUserdetails(val);
    }
    const navigate = useNavigate();
    const handleRegsiterOwner = (e,val,entityid,entityval) => {
        if (e) {e.stopPropagation(); }
        setRegisterOwner(val);
        setEmailId(entityid);
        setEntityIdowner(entityval);
    }
    const stateLogedInUser = getLoggedInUser('empId');
    const fetchVerifiedBy = async () => {
        const stateLogedInUser = getLoggedInUser('companyEmail');
        try {
            const verifiedby = await fetchGetInternalEmployeeDetailsByEmailId(stateLogedInUser);
            setSalesperson(verifiedby?.firstName);
        } catch (error) {
            console.error("Error fetching parking lot details:", error);
        }
    }; 
    useEffect(() => {
      fetchVerifiedBy();
  }, [stateLogedInUser]) 
    useEffect(() => {
        const fetchUserData = async () => {
            setLoader(true);  // Show loader before fetching data
            try {
                const userData = await fetchUsersWithValidEmpId();
                setUserdetails(userData);  // Set fetched data
            } catch (error) {
                console.error("Error fetching user data:", error);
            } finally {
                setLoader(false);  // Hide loader after data is fetched or error occurs
            }
        };

        fetchUserData();  // Call the async function
    }, [fetchUsers, registerOwner]);  // The empty array means this effect runs once after the component mounts.


    useEffect(() => {
        let result = userDetails || [];;
        // Filter by search query
        if (search) {
            result = result.filter((item) => {
                return (item.enityMailingAdderess || item.createdDate).toLowerCase().includes(search.toLowerCase());
            });
        }
        // Filter by selected status
        if (selectedStatus.length > 0) {
            result = result.filter((item) => selectedStatus.includes(item.status));
        }

        setFilter(result);
    }, [search, userDetails, selectedStatus]);
    const handleDelete = (e, val) => {
        if (e) { e.stopPropagation();}
        setSelectedRow(val.emailId);
        setOpen(true);
    };
    
    const handleEdit = (e, val) => {
        if (e) {e.stopPropagation(); }
        setDefaultaddress(val?.enityMailingAdderess);
        setUserdata(val);
        setEdit(true);
        setSalespersonloggedin(true);
    };
    

    const tableHeaderStyle = {
        headCells: {
            style: {
                height: "90px",
                fontWeight: 800,
                fontSize: "16px",
                backgroundColor: "white",
                lineHeight: "19.2px",
                fontFamily: "Lato",
                textAlign: "left",
                borderBottom: "none",
            },
        },
        cells: {
            style: {
                minWidth: '280px', // Ensure all cells have a minimum width
            },
        },
    };

    const navigateToDashboard = () => {
        navigate('/dashboard');
    };
    const handleConfirm = (name, EmailID) => {
        if (name === "confirm") {
            deleteUserByEmail(EmailID)
                .then((result) => {
                    if (result.success) {
                        // Handle successful deletion
                        setFetchusers(true);
                        setPopupVisible(false);
                        setOpen(false)
                    } else {
                        // Handle failure
                        console.error(result.message);
                    }
                })
                .catch((error) => {
                    console.error('Error during deletion:', error);
                });
        }
        if (name === "cancel") {
            setOpen(false)
        }

    }
    const handleRowClick = (row) => {
        setSelectedOnerDetails(row);
        setOwnerDetails(true); // Toggle between true and false
    };
    const handleSelectedowner = () =>{
        setOwnerDetails(false); // Toggle between true and false
    }
    return (
        <div>
            {loader && (
                <div className="loader-overlay">
                    <FadeLoader />
                </div>
            )}
            <Navbar userData={userData} />
            {registerOwner ? (
                <OwnerRegistration
                    registerOwner={registerOwner}
                    handleUserdetails={handleUserdetails}
                    handleRegsiterOwner={handleRegsiterOwner}
                    empId={stateLogedInUser}
                    EmailIdOwner={EmailIdOwner}
                    entityIdOwner={entityIdOwner}
                />
            ) : (
                !isEdit ? (
                    OwnerDetails ? (
                        <OwnerDetailsComponent 
                        handleSelectedowner={handleSelectedowner}
                        selectedRow={selectedOwnerRow}
                        propertyOwner={OwnerDetails}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        handleRegsiterOwner ={handleRegsiterOwner}
                         />
                    ) : (
                        <div>
                            <div className='container-fluid p-0'>
                                <div className="lotmanagement">
                                    <div className="row alignLeft">
                                        <div className="col-7">
                                            <div className='viewHeader'>Manage Owners & Properties</div>
                                        </div>
                                        <div className="col-3">
                                            <input
                                                type='text'
                                                className="form-control"
                                                placeholder='Search by address.'
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-2 a alignEnd">
                                            <button
                                                className="add-property-btn"
                                                onClick={(e) => handleRegsiterOwner(e,true)}
                                            >
                                                Register Property Owner
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row alignLeft">
                                        <div className="col-12 mb-2">
                                            Total Registered Owners: {userDetails?.length}
                                        </div>
                                    </div>
                                    {
                                      Array.isArray(filter) && filter.map((property, index) => (
                                    <div key={index} onClick={() => handleRowClick(property)}>
                                        <OwnerRegistartionCard
                                            handleRegsiterOwner={handleRegsiterOwner}
                                            ownerName={property?.firstName}
                                            entityName={property?.entityName}
                                            email={property?.emailId}
                                            property={property}
                                            entityId={property?.entityRegistrationID}
                                            mobilePhone={property?.mobileNumber}
                                            entityAddress={property?.enityMailingAdderess}
                                            handleEdit={(e) => handleEdit(e, property)}
                                            handleDelete={(e) => handleDelete(e, property)}
                                        />
                                    </div>
                                ))}   
                                </div>
                            </div>

                            {/* Delete confirmation dialog */}
                            <Dialog
                                classes={{ paper: 'custom-paper-class-delete' }}
                                aria-labelledby="customized-dialog-title"
                                dividers
                                open={open}
                                sx={{display: 'flex', justifyContent: 'center',alignItems: 'center', }}>
                                <DialogTitle dividers sx={{ textAlign: 'start', color: "#000;", fontSize: "24px", fontWeight: "700", padding: "5px 24px " }} id="customized-dialog-title">
                                    Are you Sure You Want to Delete This Property?
                                </DialogTitle>
                                <DialogContent style={{ border: "none", padding: "5px 22px", color: "#000;" }}>
                                    <Typography gutterBottom>
                                        <span>Once you delete this property, you will not be able to retrieve the data. This action is irreversible. Please confirm if you wish to proceed.</span>
                                    </Typography>
                                </DialogContent>
                                <DialogActions sx={{ justifyContent: 'right' }}>
                                    <Button className ='custom-button-cancel' onClick={() => handleConfirm('cancel')}>Cancel </Button>
                                    <Button className ='custom-button-delete' onClick={() => handleConfirm('confirm', selectedRowEmailID)}>Delete</Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    )
                ) : (
                    // Display MapDashboard when isEdit is true
                    <MapDashboard
                        defaultaddress={defaultaddress}
                        userData={userData}
                        entityId={userData?.entityRegistrationID}
                        empId={stateLogedInUser}
                        verificationstatus = {!userData?.isEmailPhoneVerified}
                        verifiedBy = {salespersonloggedin}
                    />
                )
            )}
        </div>
    );



};

export default OwnerRegistationDashboard;
