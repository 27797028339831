import React, { useState } from "react";
import Navbar from "./NavBar/Navbar";
import Button from '@mui/material/Button';

const UserVerify = () => {
  const [loginUserData, setloginUserData] = useState({
    EmailId: "",
    FirstName: "",
    LastName: "",
    EntityName: "",
    Search: "",
  });
  const [userData, setUserData] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedEmailIds, setSelectedEmailIds] = useState([]);
  const hostPath = process.env.REACT_APP_API_BASE_URL;
  const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;

  const handleButtonClick = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const requestBody = {
          EmailId: loginUserData.EmailId?.trim().toLowerCase() || "",
          FirstName: loginUserData.FirstName?.trim().toLowerCase() || "",
          LastName: loginUserData.LastName?.trim().toLowerCase() || "",
          EntityName: loginUserData.EntityName?.trim().toLowerCase() || "",
        };
        setSelectedEmailIds([]); // Clear selected email IDs
  
        // Perform search with the prepared JSON object
        const response = await fetch(
          `${hostPath}/api/SearchUserDetails`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-functions-key": hostHeader,
            },
            body: JSON.stringify(requestBody),
          }
        );
        const searchResults = await response.json();
  
        // Update UI based on search results
        if (searchResults.length === 0 || searchResults.length === undefined) {
          setErrors({ Search: "No users were found matching the criteria" });
          setUserData([]);
        } else {
          setUserData(searchResults);
        }
      } catch (error) {
        setErrors({ Search: "No users were found matching the criteria" });
        console.error("Error fetching data:", error);
      }
    }
  };

  const validateForm = () => {
    const errors = {};
    if (loginUserData.EmailId.trim() === "" && loginUserData.FirstName.trim() === "" && loginUserData.LastName.trim() === "" && loginUserData.EntityName.trim() === ""){
        errors.Search = "Please enter data in one of the search parameters.";
        setUserData([]);
    } else {
        if (loginUserData.EmailId.trim() !== "" && !/\S+@\S+\.\S+/.test(loginUserData.EmailId)) {
            errors.EmailId = "Email is invalid";
            setUserData([]);
        }
    }
    
    setErrors(errors);
    return Object.keys(errors).length === 0;
};

  const clearError = (fieldName) => {
    setErrors((prevErrors) => {
      return { ...prevErrors, [fieldName]: "" };
    });
  };

  const handleVerification = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    try {
      await Promise.all(
        selectedEmailIds.map((emailId) =>
          fetch(`${hostPath}/api/UpdateUserDetails`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-functions-key": hostHeader,
            },
            body: JSON.stringify({ EmailId: emailId, isVerified: true }),
          })
        )
      );
  
      alert("Users have been verified.");
      setSelectedEmailIds([]);
      loginUserData.EmailId = "";
      loginUserData.FirstName = "";
      loginUserData.LastName = "";
      loginUserData.EntityName = "";
      setUserData([]);
    } catch (error) { // Correctly placed catch block for catching errors
      console.error("Error verifying users:", error);
      alert("An error occurred during the verification process. Please try again.");
    }
  };

  return (
    <div>
      <Navbar />
      <div className="container-fluid p-0">
        <div className="lotmanagement">
          <div className="row" style={{ textAlign: "left" }}>
            <div className="col-8">
              <div className="viewHeader" style={{fontSize: "32px"}}>Verification of a user</div>
            </div>
            <div className="row" style={{ textAlign: "left", marginLeft: "10px"}}>
              <label
                htmlFor="username"
                className="userName loginlabel"
              >
                Email
              </label>
              <input
                type="text"
                style={{width: "500px", marginLeft: "10px"}}
                className={`form-control ${
                  loginUserData.EmailId ? "input-active" : ""
                }`}
                id="username"
                placeholder="Enter Email"
                value={loginUserData.EmailId}
                onChange={(e) => {
                  clearError("EmailId");
                  setloginUserData({
                    ...loginUserData,
                    EmailId: e.target.value,
                  });
                }}
              />
              {errors.EmailId && (
                <div className="error-message mandatorymark">
                  {errors.EmailId}
                </div>
              )}
              <label
                htmlFor="username"
                className="userName loginlabel"
                align="left"
              >
                First Name
              </label>
              <input
                maxLength="50"
                style={{width: "500px", marginLeft: "10px"}}
                type="text"
                className={`form-control ${
                  loginUserData.FirstName ? "input-active" : ""
                }`}
                id="username"
                placeholder="Enter First Name"
                value={loginUserData.FirstName}
                onChange={(e) => {
                  const value = e.target.value;
                  const regex = /^[a-zA-Z ]*$/; // Allow spaces along with letters
                  if (regex.test(value) || value === "") {
                    clearError("FirstName");
                    setloginUserData({
                      ...loginUserData,
                      FirstName: value,
                    });
                  }
                }} 
              />
              {errors.FirstName && (
                <div className="error-message mandatorymark">
                  {errors.FirstName}
                </div>
              )}
              <label
                htmlFor="username"
                className="userName loginlabel"
                align="left"
              >
                Last Name
              </label>
              <input
                maxLength="50"
                style={{width: "500px", marginLeft: "10px"}}
                type="text"
                className={`form-control ${
                  loginUserData.LastName ? "input-active" : ""
                }`}
                id="username"
                placeholder="Enter Last Name"
                value={loginUserData.LastName}
                onChange={(e) => {
                  const value = e.target.value;
                  const regex = /^[a-zA-Z ]*$/; // Allow spaces along with letters
                  if (regex.test(value) || value === "") {
                    clearError("LastName");
                    setloginUserData({
                      ...loginUserData,
                      LastName: value,
                    });
                  }
                }}
              />
              {errors.LastName && (
                <div className="error-message mandatorymark">
                  {errors.LastName}
                </div>
              )}
              <label
                htmlFor="username"
                className="userName loginlabel"
                align="left"
              >
                Entity Name
              </label>
              <input
                maxLength="100"
                style={{width: "500px", marginLeft: "10px"}}
                type="text"
                className={`form-control ${
                  loginUserData.EntityName ? "input-active" : ""
                }`}
                id="username"
                placeholder="Enter Enity Name"
                value={loginUserData.EntityName}
                onChange={(e) => {
                  const value = e.target.value;
                  const regex = /^[a-zA-Z0-9\s]+$/; 
                  if (regex.test(value) || value === "") { 
                    clearError("EntityName");
                    setloginUserData({
                      ...loginUserData,
                      EntityName: value,
                    });
                  }
                }}
              />
              {errors.EntityName && (
                <div className="error-message mandatorymark">
                  {errors.EntityName}
                </div>
              )}
            </div>
            <Button
                style={{marginLeft: "30px", marginTop: "20px", backgroundColor: "green", color: "#fff"}}
                className='custom-button-cancel'
                type="button"
                onClick={handleButtonClick}
              >
                Search User
              </Button>
              {errors.Search && (
                <div 
                  className="error-message mandatorymark"
                  style={{ marginLeft: '16px', marginTop: '10px', fontSize: '1.2em' }} // Adjust values as needed
                >
                  {errors.Search}
                </div>
              )}
              {userData.length > 0 && (
                <div className="table-responsive"style={{marginLeft: "20px", marginTop: "20px"}}>
                  <table className="table table-bordered table-striped">
                    <thead className="thead-dark">
                      <tr>
                        <th>Select</th>
                        <th>Email ID</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Entity Name</th>
                      </tr>
                    </thead>
                    <tbody>
                    {userData.map((user) => (
                      <tr key={user.id}>
                        <td>
                          {user.isVerified ? (
                            <span>&#10003;</span> // This is a simple checkmark, replace with your preferred icon
                          ) : (
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedEmailIds((prev) => [...prev, user.emailId]);
                                } else {
                                  setSelectedEmailIds((prev) => prev.filter((emailId) => emailId !== user.emailId));
                                }
                              }}
                            />
                          )}
                        </td>
                        <td>{user?.emailId}</td>
                        <td>{user?.firstName}</td>
                        <td>{user?.lastName}</td>
                        <td>{user?.entityName}</td>
                      </tr>
                    ))}
                  </tbody>
                  </table>
                  <Button
                    style={{marginTop: "20px", backgroundColor: "green", color: "#fff"}}
                    className='custom-button-cancel'
                    type="button"
                    onClick={handleVerification}
              >
                Save
              </Button>
               </div>
                
              )}
            </div>
          </div>
      </div>
    </div>
  );
};

export default UserVerify;
