import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/HowItWorks.css";

const HowItWorks = () => {
  const navigate = useNavigate();

  const handleSignUpClick = () => {
    navigate("/signup");
  };

  return (
    <section className="how-it-works">
      <div className="container">
        <h2>How It Works in 8 Minutes</h2>
        <div className="steps">
          <div className="step">
            <div className="number">01</div>
            <h3>Sign up and create your FREE account</h3>
            <p>
              You choose to designate whatever you want, from pick-up and
              drop-off locations to any parking space(s) and the times available
              for parking electric Autonomous Vehicles/Robotaxis (<b><u>with no human
              drivers</u></b>). Then, you get paid for the parking (and no worries if
              your designated parking spaces are full at the designated time(s),
              as the Robotaxi will just drive away if the parking spot(s) are
              occupied).
            </p>
          </div>
          <div className="step">
            <div className="number">02</div>
            <h3>We Do the Work </h3>
            <p>
              Our platform manages everything, from integrating your lot into
              our system to coordinating with Robotaxi operators.
            </p>
          </div>
          <div className="step">
            <div className="number">03</div>
            <h3>Get Involved in Sustainability</h3>
            <p>
              Contribute to a cleaner, greener future by supporting the use of
              zero-carbon vehicles in your parking lot. And having immediate
              Robotaxi availability for people in or using your facility while
              you get paid for it.
            </p>
          </div>
        </div>
        <div className="centered-button">
          <button className="sign-up-button" onClick={handleSignUpClick}>
            Sign Up Today!
          </button>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
