import React from 'react';
import Navbar from '../NavBar/Navbar';

const Earnings = () => {
    return (
        <div>
            <Navbar />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh', fontSize: '2rem' }}>
                Coming soon
            </div>
        </div>
    );
};

export default Earnings;