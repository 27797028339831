import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { isDisabled } from '@testing-library/user-event/dist/utils';
import Navbar from './NavBar/Navbar'

const UserDelete = () => {
    const navigate = useNavigate();
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const hostPath = process.env.REACT_APP_API_BASE_URL;

    const [loginUserData, setloginUserData] = useState({
        EmailId: '',
    });
    const [errors, setErrors] = useState({});

    const handleButtonClick = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            let deleteEmail = {
                EmailId: loginUserData.EmailId.toLowerCase(),
            };
            console.log("Email sent to server:", deleteEmail.EmailId);
            try {
                const response = await axios.post(`${hostPath}/api/DeleteUserBasedOnEmailId`, deleteEmail, {
                    headers: { "x-functions-key": hostHeader }
                });

                if (response.data) {
                    console.log("response.data", response.data);
                    if (response.data.status === false) {
                        setErrors({ ...errors, EmailId: response.data.responseMessage });
                    } else {
                        localStorage.clear();
                        alert("User has been deleted successfully!")
                        navigate('/');
                    }
                } else {
                    setErrors({ ...errors, Password: 'Please enter the correct password and try again!' });
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };
    const validateForm = () => {
        const errors = {};

        if (!loginUserData.EmailId) {
            errors.EmailId = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(loginUserData.EmailId)) {
            errors.EmailId = "Email is invalid";
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };
    const handleButtonClick1 = () => {
        navigate('/');
    }

    const clearError = (fieldName) => {
        setErrors((prevErrors) => {
            return { ...prevErrors, [fieldName]: '' };
        });
    };
    return (
        <div>  <Navbar />
            <div className="container-fluid p-0">
                <div className="row m-0">
                    <div className="col-md-12 d-flex align-items-center justify-content-center">
                        <div className="login-form loginfirst">
                            <div className="login">
                                <h3 className='loginheader textleft'>Removing User</h3>

                            </div>
                            <div className="otp-input mb-4">
                                <div className="Username mb-4 textleft formbox">
                                    <label htmlFor="username" className='userName loginlabel'>Email</label><span className='mandatorymark loginlabel'>*</span>
                                    <input
                                        type="text"
                                        className={`form-control ${loginUserData.EmailId ? 'input-active' : ''}`}
                                        id="username"
                                        placeholder='Enter Email'
                                        value={loginUserData.EmailId}
                                        onChange={(e) => {
                                            clearError('EmailId');
                                            setloginUserData({ ...loginUserData, EmailId: e.target.value })
                                        }}
                                    />
                                    {errors.EmailId && <div className="error-message mandatorymark">{errors.EmailId}</div>}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mb-5">
                                <button type="button" className='forgotpasswordbutton' onClick={handleButtonClick}>Delete User</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserDelete;