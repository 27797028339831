import React, { useState, useEffect, } from 'react';
import atlas from 'azure-maps-control';
import * as drawing from "azure-maps-drawing-tools";
import GeoParkingLogo from '../../Images/GeoParkingLogo.png';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import ScheduleAvailability from '../scheduleAvailability/scheduleAvailability'
import BankDetailsMain from '../bankDetails/bankDetailsMain'
import ReviewAndConfirm from '../reviewConfirm/reviewandConfirm';
import { Soap } from '@mui/icons-material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const LocateProperty = () => {
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const location = useLocation();
    const stateLogedInUser  = location.state;
    console.log("locatepropertylogedinuser",stateLogedInUser)
    
    var map;
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [isFlagSet, setIsFlagSet] = useState(false);
    const [islocateoptions, setlocateoptions] = useState(false);
    const [issearchdisabled, setSearchdisabled] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [activeStep, setActiveStep] = React.useState(0);
    const [selectedMarkingOptions, setselectedMarkingOptions] = useState([]);
    const [concateLatLang, setLatLang] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [selectedmarkupData, setselectedmarkup] = useState([])
    const [mySearchOptions, setMySearchOptions] = useState([])
    const [markupData, setSelectedMarkedupData] = useState([])
    const [mapmarkerdata, setmapmarkerdata] = useState([])
    const [selectedLocationIndex, setSelectedLocationIndex] = useState(-1);
    const [isupdatedselecteddata, setupdatedselecteddata] = useState([])
    const [searchValue, setSearchValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [addressvalue, setAddressvalue] = useState();
    const [hassearchError, setSearcherror] = useState(false);
    const [hasselectedError, sethasselectedError] = useState(false)
    const [allareasMarked, setAllareasmarked] = useState(false);
    const [areasMarked, setareasmarked] = useState([]);
    const [isconfirmselected, setConfirmselected] = useState(false);
    const [bankdetails, setBankdetails] = useState(false);
    const [reviewlocation, setReviewlocation] = useState(false);
    const [position, setPosition] = useState([]);
    const [selectedValue, setSelectedValue] = useState(''); // Set default to Custom
    const [bankaccountdetails, setbankaccountdetails] = useState({});
    const [errors, setErrors] = useState({});
    const [mapinit, setMapinit] = useState(true);
    const [userEmailID, setEmailID] = useState({})
    const [timeValues, setTimeValues] = useState({
        Monday: "",
        Tuesday: "",
        Wednesday: "",
        Thursday: "",
        Friday: "",
        Saturday: "",
        Sunday: "",
    });
    const [errorsbank, setbankErrors] = useState({});
    const [accountinfo, setAccountinfo] = useState({
        BankName: bankaccountdetails ? bankaccountdetails.bankName : "",
        AccountHolderName: bankaccountdetails ? bankaccountdetails.accountHolderName : "",
        AccountType: bankaccountdetails ? bankaccountdetails.accountType : "",
        AccountNumber: bankaccountdetails ? bankaccountdetails.accountNumber : "",
        RoutingNumber: bankaccountdetails ? bankaccountdetails.routingNumber : "",
        SWIFT_BIC_Code: bankaccountdetails ? bankaccountdetails.swifT_BIC_Code : "",
        Add_Ass_with_Account: bankaccountdetails ? bankaccountdetails.add_Ass_with_Account : "",
    });
    console.log("bankaccountdetails",bankaccountdetails);
    const navigate = useNavigate();
    const popUpData = [];
    const [searchError, setSearchError] = useState(false);
    const steps = [
        { title: 'Locate Property' },
        { title: 'Mark pickup/drop-off area' },
        { title: 'Schedule Availability' },
        { title: 'Bank Details Entry' },
        { title: 'Review and Confirm' },
    ];
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleSteps = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
    const timeSlotesAvailability = () =>{
          setBankdetails(true);
          handleSteps()
    }

    const handleReset = () => {
        setActiveStep(0);
    };
    const handleradiobutton = (event) => {
        const selectedValue = event.target.value;
        setSelectedValue(selectedValue);
    };
    const handleChange = (day) => (event) => {
        const timeSlotRegex = /^([1-9]|1[0-2])(AM|PM)-([1-9]|1[0-2])(AM|PM)$/;
        const { value } = event.target;
        setTimeValues({ ...timeValues, [day]: value });

        if (!timeSlotRegex.test(value)) {
            setErrors({ ...errors, [day]: 'Invalid time slot format' });
        } else {
            const newErrors = { ...errors };
            delete newErrors[day];
            setErrors(newErrors);
        }
    };
    console.log("activeStep", activeStep);

    const validateFields = () => {
        let tempErrors = {};
        let isValid = true;
        const validateField = (field, value, regex, emptyMessage, invalidMessage) => {
            if (value?.trim() === "") {
                tempErrors[field] = emptyMessage;
                isValid = false;
            } else if (regex && !regex.test(value)) {
                tempErrors[field] = invalidMessage;
                isValid = false;
            }
        };
        validateField('BankName', accountinfo.BankName, /^[A-Za-z\s]+$/, 'Bank Name is required', 'Please enter alphabets only');
        validateField('AccountHolderName', accountinfo.AccountHolderName, /^[A-Za-z\s]+$/, 'Account Holder Name is required', 'Please enter alphabets only');
        validateField('AccountNumber', accountinfo.AccountNumber, /^\d{8,12}$/, 'Account Number is required', 'Account Number must be between 8 and 12 digits');
        validateField('RoutingNumber', accountinfo.RoutingNumber, /^\d{9}$/, 'Routing Number is required', 'Routing Number must be exactly 9 digits');
        validateField('AccountType', accountinfo.AccountType, null, 'Account Type is required', '');
        validateField('Add_Ass_with_Account', accountinfo.Add_Ass_with_Account, null, 'Address associated with the Account is required', '');
        setbankErrors(tempErrors);
        return isValid;
    };
    console.log("accountinfo", accountinfo);
    const handleInputChange = (account) => (e) => {
        const { name, value } = e.target;
        setAccountinfo({ ...accountinfo, [account]: value });
        const validateField = (name, value, regex, emptyMessage, invalidMessage) => {
            if (value.trim() === "") {
                setbankErrors({ ...errors, [name]: emptyMessage });
            } else if (regex && !regex.test(value)) {
                setbankErrors({ ...errors, [name]: invalidMessage });
            } else {
                setbankErrors({ ...errors, [name]: "" });
            }
        };
        switch (name) {
            case 'BankName':
                validateField(name, value, /^[a-zA-Z\s]*$/, 'Bank Name is required', 'Please enter alphabets only');
                break;
            case 'AccountHolderName':
                validateField(name, value, /^[a-zA-Z\s]*$/, 'Account Holder Name is required', 'Please enter alphabets only');
                break;
            case 'AccountType':
                validateField(name, value, null, 'Account Type is required', '');
                break;
            case 'AccountNumber':
                validateField(name, value, /^\d{8,12}$/, 'Account Number is required', 'Account Number must be between 8 and 12 digits');
                break;
            case 'RoutingNumber':
                validateField(name, value, /^\d{9}$/, 'Routing Number is required', 'Routing Number must be exactly 9 digits');
                break;
            case 'SWIFT_BIC_Code':
                // Add validation if needed
                break;
            case 'Add_Ass_with_Account':
                validateField(name, value, null, 'Address associated with the Account is required', '');
                break;
            default:
                break;
        }
    };
    let pslCounter = 1;
    async function InitMap() {
        let drawingManager;
        let toolBarVar;

        // Initialize the map with the specified options
        let mapElement = document.getElementById('myMap');
        let map;
        if (mapElement && mapinit) {
            // Initialize the map only if the container element exists
            map = new atlas.Map('myMap', {
                center: [longitude || -122.18402494821507, latitude || 47.61373420362662],
                zoom: 18,
                language: 'en-US',
                style: 'satellite',
                authOptions: {
                    authType: 'subscriptionKey',
                    subscriptionKey: 'iKSTDEFy2eA9QfhSETwDwwnQoR_36iCV5wv18ubSOBw'
                }
            });
        }
        if (map && map.events && map.events.add) {
            map.events.add('ready', function () {
                const zoomControl = new atlas.control.ZoomControl();
                if (map.controls && map.controls.add) {
                    map.controls.add(zoomControl, { position: 'bottom-right' });
                    map.controls.add(new atlas.control.StyleControl({
                        mapStyles: ['road', 'road_shaded_relief', 'grayscale_light', 'night', 'grayscale_dark', 'satellite', 'satellite_road_labels']
                    }), { position: 'top-left' });
                }

                if (isFlagSet) {
                    let data = [];
                    const selectedOption = JSON.parse(localStorage.getItem("selectedOption"));
                    if (selectedOption.some(option => ["PUE", "DOE", "PUL", "DOL", "PUX", "DOX"].includes(option))) {
                        data = ["draw-point"];
                    } else if (selectedOption.some(option => ["PKE", "PKX", "PSL"].includes(option))) {
                        data = ["draw-polygon"];
                    } else {
                        data = ["draw-point", "draw-polygon"];
                    }
                    toolBarVar = new drawing.control.DrawingToolbar({ position: 'top-right', style: 'light', buttons: data });
                    drawingManager = new drawing.drawing.DrawingManager(map, {
                        toolbar: toolBarVar,
                        interactionType: 'hybrid',
                        freehandInterval: 3
                    });
                }
            });
        }

        if (allareasMarked && map && map.events && map.events.add) {
            setMapinit(false);
            areasMarked.forEach((area, index) => {
                const markerHTMLContent = `
                    <div style="position: relative;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="60" viewBox="0 0 24 36" fill="#6ACD54">
                            <path d="M12 0C5.383 0 0 5.383 0 12c0 7.188 10.83 22.812 10.83 22.812C11.147 34.81 12 34.365 12 34.365S12.853 34.81 13.17 34.812C13.17 34.812 24 19.188 24 12C24 5.383 18.617 0 12 0z"/>
                            <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="black" font-size="8">${area.Name.substring(0, 3)}</text>
                        </svg>
                    </div>
                `;


                if (area.Coardinates && area.Coardinates.Latitude && area.Coardinates.Longitude) {
                    const marker = new atlas.HtmlMarker({
                        draggable: true,
                        position: [area.Coardinates.Latitude, area.Coardinates.Longitude],
                        htmlContent: markerHTMLContent
                    });

                    // Remove any element that might look like a cross mark
                    const tempContainer = document.createElement('div');
                    tempContainer.innerHTML = marker.getOptions().htmlContent;
                    const closeButton = tempContainer.querySelector(`#closeButton-${index}`);
                    if (closeButton) {
                        closeButton.remove(); // or modify it
                    }

                    marker.getOptions().htmlContent = tempContainer.innerHTML;

                    map.events.add('dragend', marker, (function (currentArea) {
                        return function (e) {
                            const position = marker.getOptions().position;
                            const updatedLat = position[0];
                            const updatedLng = position[1];

                            setSelectedMarkedupData(prevData => {
                                if (!prevData || !Array.isArray(prevData)) {
                                    return [];
                                }

                                const existingIndex = prevData.findIndex(data => data.Coardinates.Title === currentArea.Coardinates.Title);

                                if (existingIndex !== -1) {
                                    const updatedData = [...prevData];
                                    updatedData[existingIndex] = {
                                        ...updatedData[existingIndex],
                                        Coardinates: {
                                            ...updatedData[existingIndex].Coardinates,
                                            Latitude: updatedLat,
                                            Longitude: updatedLng
                                        },
                                        latandlong: `Latitude: ${updatedLat} Longitude: ${updatedLng}`
                                    };
                                    setareasmarked(updatedData);
                                    return updatedData;
                                } else {
                                    const newArea = {
                                        ...currentArea,
                                        Coardinates: {
                                            ...currentArea.Coardinates,
                                            Latitude: updatedLat,
                                            Longitude: updatedLng
                                        },
                                        latandlong: `Latitude: ${updatedLat} Longitude: ${updatedLng}`
                                    };
                                    const newData = [...prevData, newArea];
                                    setareasmarked(newData);
                                    return newData;
                                }
                            });
                        };
                    })(area));
                    map.markers.add(marker);
                }
            });
            return;
        }
        console.log("areasMarked",areasMarked);
        var data = selectedMarkingOptions == null || selectedMarkingOptions == "" ? [] : selectedMarkingOptions;
        if (data[0]) {
            let updateMarkedData;
            map.events.add('click', (e) => {
                const clickedLatitude = e.position[0];
                const clickedLongitude = e.position[1];
                if (data[0] !== "PSL - Parking Space Location") {
                    map.markers.clear();
                }
                if (data[0]) {
                    const markedLocation = data[0];
                    updateMarkedData = (newMarkData) => {
                        setSelectedMarkedupData(prevMarkupData => {
                            const existingIndex = prevMarkupData.findIndex(item => item.Coardinates.Title === newMarkData.Coardinates.Title);
                            if (existingIndex !== -1) {
                                const updatedMarkupData = [...prevMarkupData];
                                updatedMarkupData[existingIndex] = newMarkData;
                                setSelectedMarkedupData(updatedMarkupData);
                                pslCounter++;
                            } else {
                                setSelectedMarkedupData([...prevMarkupData, newMarkData]);
                                pslCounter++;
                            }
                        });
                    };

                    const updatedselectedOptions = data.filter(x => !markedLocation.includes(x));
                    setupdatedselecteddata(updatedselectedOptions);
                    setselectedMarkingOptions(updatedselectedOptions);

                    let markdata = {
                        "Name": markedLocation,
                        "latandlong": `Latitude: ${clickedLatitude} Longitude: ${clickedLongitude}`,
                        "Coardinates": {
                            "Title": markedLocation.startsWith('PSL') ? markedLocation.substring(0, 3) + pslCounter : markedLocation.substring(0, 3),
                            "Latitude": clickedLatitude,
                            "Longitude": clickedLongitude
                        }
                    };
                    updateMarkedData(markdata);
                    const markerHTMLContent = `
                <div style="position: relative; width: 35px; height: 60px;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="60" viewBox="0 0 24 36" fill="#6ACD54">
                        <path d="M12 0C5.383 0 0 5.383 0 12c0 7.188 10.83 22.812 10.83 22.812C11.147 34.81 12 34.365 12 34.365S12.853 34.81 13.17 34.812C13.17 34.812 24 19.188 24 12C24 5.383 18.617 0 12 0z"/>
                        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="black" font-size="8">${markedLocation.substring(0, 3)}</text>
                    </svg>
                    <div id="closeButton" style="position: absolute; top: 0px; right: 0px; width: 20px; height: 20px; display: flex; align-items: center; justify-content: center; color: black; background: grey; cursor: pointer; border-radius: 50%; transform: translate(50%, -50%);">x</div>
                </div>`

                    const marker = new atlas.HtmlMarker({
                        draggable: true,
                        text: markedLocation.substring(0, 3),
                        position: [clickedLatitude, clickedLongitude],
                        htmlContent: markerHTMLContent
                    });
                    const closeButton = marker.getElement().querySelector('#closeButton');
                    closeButton.addEventListener('click', function (e) {
                        map.markers.remove(marker);
                        e.stopPropagation();
                        setSelectedMarkedupData(prevData => prevData.filter(data => data.Coardinates.Latitude !== clickedLatitude && data.Coardinates.Longitude !== clickedLongitude));
                    });

                    map.markers.add(marker);
                    const popup = new atlas.Popup({
                        pixelOffset: [0, -30],
                        content: `<div id="dataPopup" style="padding:10px">${clickedLatitude} and ${clickedLongitude}</div>`,
                        position: [clickedLatitude, clickedLongitude]
                    });
                } else {
                }
            });
        }
    }
    const markNextLocation = () => {
        InitMap();
    }
    const handlelocateback = () => {
        window.location.reload();
    }
    console.log("selectedMarkingOptions",selectedMarkingOptions)
    console.log("selectedOptions",selectedOptions)
    useEffect(() => {
        setAccountinfo({
            BankName: bankaccountdetails ? bankaccountdetails.bankName : "",
            AccountHolderName: bankaccountdetails ? bankaccountdetails.accountHolderName : "",
            AccountType: bankaccountdetails ? bankaccountdetails.accountType : "",
            AccountNumber: bankaccountdetails ? bankaccountdetails.accountNumber : "",
            RoutingNumber: bankaccountdetails ? bankaccountdetails.routingNumber : "",
            SWIFT_BIC_Code: bankaccountdetails ? bankaccountdetails.swifT_BIC_Code : "",
            Add_Ass_with_Account: bankaccountdetails ? bankaccountdetails.add_Ass_with_Account : "",
        });
    }, [bankaccountdetails]);
    useEffect(()=>{
            const emailId = {
                "EmailId": stateLogedInUser?.stateLogedInUser?.stateLogedInUser?.LogedInUser[0]?.emailId
            }
            console.log("emailid",emailId);
            const requestOptions = {
                method: 'POST',
                headers: { "x-functions-key": `${hostHeader}` },
                body: emailId
            };
                axios.post(`${hostPath}/api/GetBankDetails`, requestOptions.body, { headers: requestOptions.headers })
                    .then(response => {
                        if (response.data) {
                            const bankdetails = response.data;
                            console.log(bankdetails);
                            setbankaccountdetails(bankdetails);
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
    },[])
    useEffect(() => {
        InitMap();
    }, [concateLatLang, latitude, longitude, areasMarked, position, isconfirmselected]);
    useEffect(() => {
        if (searchValue?.trim() !== '') {
            setSearcherror(false);
            // Fetch suggestions from API
            axios.get(`https://atlas.microsoft.com/search/fuzzy/json?&subscription-key=iKSTDEFy2eA9QfhSETwDwwnQoR_36iCV5wv18ubSOBw&api-version=1.0&language=en-US&query=${searchValue}&filter=countryCode=US`)
                .then(response => {
                    const results = response.data.results;
                    if (results && results.length > 0) {
                        const suggestedLocations = results?.filter(result => result.address.countryCode === 'US').map(result => result.address.freeformAddress);
                        setSuggestions(suggestedLocations);
                    }
                })
                .catch(error => {
                    console.error('Error fetching location suggestions:', error);
                });
        } else {
            setSuggestions([]);
        }
    }, [searchValue]);

    const handleSearchChange = (value) => {
        const invalidCharacters = /[^a-zA-Z0-9\s,]/;
        if (invalidCharacters.test(value)) {
            setSearchError(true);
        } else {
            setSearchError(false);
        }
        setSearchValue(value);
      };

    const handleClose = () => {
        if(selectedMarkingOptions.length > 0){
            setlocateoptions(false);
            setIsFlagSet(true);
            InitMap();
            sethasselectedError(false)
            setActiveStep(1);
        }
        else{
            sethasselectedError(true)
        }
    };
    const handleCancel = () => {
        sethasselectedError(false)
        setlocateoptions(false)
        setselectedMarkingOptions([])
        setSelectedOptions([])
    }
    const handleConfirm = (val) => {
        setConfirmselected(val);
        setMapinit(true);
        handleSteps();
    }
    const handlebankdetails = (val) => {
        setBankdetails(val);
    }
    const handleReview = (val) => {
        setReviewlocation(val);
    }
    const handlebankaccountdetails = (val) => {
        setbankaccountdetails(val);
    }
    const handleButtonClick = (e) => {
        if (!addressvalue) {
            setSearcherror(true);
            return;
        }
        if(addressvalue && activeStep == 0){
            setlocateoptions(true);
           
        }else if(selectedOptions.length>0 ){
            setlocateoptions(false);
        }
        
        
        var datalength = localStorage.getItem("selectedOption") == null ? JSON.parse(localStorage.getItem("selectedOption")) : []
        if (markupData.length > 0) {
            setAllareasmarked(true);
            setareasmarked(markupData);
        }
        else {
            if (activeStep) {
                localStorage.setItem("selectedOption", []);
            }
            else {
                if (selectedOptions.length === 0) {
                    setlocateoptions(true);
                    setSearchdisabled(true);
                }
            }
        }
    }
    const searchLocation = (locationName) => {
        var data = []
        localStorage.setItem("selectedOption", '');
        localStorage.setItem("selectedMarkerOption", '');
        localStorage.setItem('selectedLocatons', '');
        data = JSON.parse(localStorage.getItem("formData")) != null ? JSON.parse(localStorage.getItem("formData")) : [];
        let location = {
            "ParkingLotAddress": locationName
        }
        data.location = locationName
        localStorage.setItem("formData", JSON.stringify(data))
        axios.get(`https://atlas.microsoft.com/search/address/json?&subscription-key=iKSTDEFy2eA9QfhSETwDwwnQoR_36iCV5wv18ubSOBw&api-version=1.0&language=en-US&query=${locationName}`)
            .then((response) => {
                const results = response.data.results;
                localStorage.setItem('longitude', '')
                localStorage.setItem('latitude', '')
                if (results && results.length > 0) {
                    const firstResult = results[0];
                    const { position } = firstResult;
                    localStorage.setItem('latitude', position.lat)
                    localStorage.setItem('longitude', position.lon)
                    setLatitude(position.lat);
                    setLongitude(position.lon);
                    const bounds = [];
                    InitMap();
                }
            })
            .catch((error) => {
                console.error('Error fetching location data:', error);
            });
    };
    const getDataFromAPI = (event, value) => {
        if (value) {
            const locationName = value.trim();
            if (locationName !== '') {
                setAddressvalue(locationName);
                setTimeout(() => {
                    searchLocation(locationName, map);
                }, 1000);
            } else {
                setMySearchOptions([]);
            }
        } else {
            setMySearchOptions([]);
        }
    };
    const handleCheckboxChange = (option) => {
        var data = selectedMarkingOptions != null && selectedMarkingOptions != '' ? selectedMarkingOptions : [];
        if (!data?.includes(option)) {
            data.push(option)
            sethasselectedError(false)
            setselectedMarkingOptions(data)
        }
        else if (data?.includes(option)) {
            data.pop(option)
            setselectedMarkingOptions(data)
        }
        const isSelected = selectedOptions.includes(option);
        setSelectedOptions(prevOptions =>
            isSelected
                ? prevOptions.filter(item => item !== option)
                : [...prevOptions, option]
        );
    };

    console.log("accountinfo",accountinfo)
    console.log("bankaccountdetails",bankaccountdetails)
    console.log(selectedMarkingOptions,"selectedMarkingOptions")
    return (
        <div className='container-fluid p-0'>
            <div className="dashboard">
                <div className="rectangle" />
                <img className="screenshot" alt="Screenshot" src={GeoParkingLogo} />
                <div className="text-wrapper">Dashboard</div>
                <div className="text-wrapper-2">My Properties</div>
                <div className="text-wrapper-3">Payments</div>
                <div className="text-wrapper-myproperties" />
                <div className="ellipse text-wrapper-4" >MN</div>
            </div>
            <div className='lotmanagment'>
                <div className="row">
                    <div className="col-md-2">
                        <div style={{ margin: '2% 0% 3% 0%', textAlign: "left" }}>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                {steps.map((step, index) => (
                                    <Step key={step.title}>
                                        <StepLabel
                                            optional={
                                                index === 2 ? (
                                                    <Typography variant="caption">Last step</Typography>
                                                ) : null
                                            }
                                        >
                                            {step.title}
                                        </StepLabel>
                                        <StepContent>
                                            <Box sx={{ mb: 2 }}>
                                                <div>
                                                </div>
                                            </Box>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                            {activeStep === steps.length && (
                                <Paper square elevation={0} sx={{ p: 3 }}>
                                    <Typography>All steps completed - you&apos;re finished</Typography>
                                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                        Reset
                                    </Button>
                                </Paper>
                            )}
                        </div>
                    </div>

                    <div className="col-md-10 ">
                        {!isconfirmselected ? (
                            <div className='row'>
                                <div className="row mb-5 mapoverlay">
                                    <div className="col-md-7">
                                        {!isFlagSet ? (
                                            <div style={{ textAlign: "left", backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "10px", borderRadius: "5px", width: "400px", border: "1px solid white", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)" }}>
                                            <h6 style={{ color: "white" }}>Find Your Property</h6>
                                            <span style={{ color: "white" }}>Enter your location details to locate your property on the map.</span>
                                        </div>
                                        ) : (
                                            !allareasMarked ? (
                                                <div style={{ textAlign: "left" }}>
                                                    <h6>Define Robo Taxi Pickup and Drop-off Zones</h6>
                                                    <span>Use the map to mark the precise areas where Robo Taxis will pick up passengers and drop them off.</span>
                                                    {selectedMarkingOptions[0] && (
                                                        <div className='markoption'>
                                                            Please select the : {activeStep == 1 ? selectedMarkingOptions[0] : null}
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (

                                                <div style={{marginRight:"500px"}}><h5>Move the marker to edit, click "Back" to reselect, or click "Confirm" to schedule a time.
                                                    .</h5>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <div className="col-md-5" style={{marginLeft:"-8%"}}>
                                        <Autocomplete
                                            style={{ width: 300 ,backgroundColor: "white", marginLeft: "-0%"}}
                                            freeSolo
                                            autoComplete
                                            autoHighlight
                                            options={suggestions}
                                            onChange={getDataFromAPI}
                                            disabled={issearchdisabled}
                                            value={addressvalue}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    onChange={(e) => handleSearchChange(e.target.value)}
                                                    variant="outlined"
                                                    label="Search Box"
                                                    disabled={issearchdisabled}
                                                    value={addressvalue}
                                                />
                                            )}
                                        />
                                        {searchError && (
                                            <div style={{ color: 'red', marginTop: '8px' }}>
                                                Enter alphabetic characters only
                                            </div>
                                        )}
                                        {hassearchError && (
                                            <div style={{ color: 'red', marginTop: '8px', marginRight: '250px' }}>
                                                Address is required
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className='maplocation'>
                                    <div className="row">
                                        <div className="col-md-12">
                                            {!isFlagSet ? (
                                                <div id="myMap" data-testid="myMap" style={{ height: '70vh', width: '95%', margin: '0% 3% 1% 3%' }}></div>
                                            ) : (
                                                <div id="myMap" data-testid="myMap" style={{ height: '70vh', width: '95%', margin: '0% 3% 1% 3%' }}></div>
                                            )}
                                        </div>
                                        <div className="col-md-2">

                                            {/* {selectedOptions && (
                                                <ul>
                                                    {selectedOptions.map((item, index) => (
                                                        <li key={index}><strong className='list'>{item}</strong></li>
                                                    ))}
                                                </ul>
                                            )} */}
                                        </div>
                                    </div>
                                    <div className=" mb-3" style={{ textAlign: "end" }}>
                                        {isupdatedselecteddata[0] ?
                                            <button type="submit" className='nextBtn nextbtninmap' onClick={markNextLocation}>Select Next</button> :
                                            allareasMarked ?
                                                <div className='backconfirmbtn'>
                                                    <button type="submit" className='finishbtn' onClick={handlelocateback} style={{ marginRight: "10px" }}>Back</button>
                                                    <button type="submit" className='nextBtn' onClick={() => handleConfirm(true)}>Confirm</button>
                                                </div>
                                                :
                                                <button type="submit" className='nextBtn nextbtninmap' onClick={handleButtonClick}>Next</button>}
                                    </div>
                                    <BootstrapDialog
                                        onClose={handleClose}
                                        aria-labelledby="customized-dialog-title"
                                        open={islocateoptions}
                                    >
                                        <DialogTitle  >
                                            <span className="subheaders">Choose Marking Options</span> <br />

                                            <span className='subcontent'> Select the options you wish to mark on the map</span>
                                        </DialogTitle>
                                        <DialogContent className='border: none'>

                                            <div>
                                                <Typography gutterBottom>
                                                    <span className="markingsubheader"> Pick up and drop off areas</span>
                                                </Typography>

                                                <div className="Parking lot images mb-1 ">
                                                    <div className={`markingoptions ${selectedOptions.includes("PUE - Pick Up Entrance") ? 'selected' : ''}`}
                                                        onClick={() => handleCheckboxChange("PUE - Pick Up Entrance")}>

                                                        <span>PUE - Pick Up Entrance</span>
                                                    </div>
                                                    <div className={`markingoptions ${selectedOptions.includes("DOE - Drop Off Entrance") ? 'selected' : ''}`}
                                                        onClick={() => handleCheckboxChange("DOE - Drop Off Entrance")}>

                                                        <span>DOE - Drop Off Entrance</span>
                                                    </div>
                                                    <div className={`markingoptions ${selectedOptions.includes("PUL - Pick Up Location") ? 'selected' : ''}`}
                                                        onClick={() => handleCheckboxChange("PUL - Pick Up Location")}>

                                                        <span>PUL - Pick Up Location</span>
                                                    </div>
                                                </div>
                                                <div className="Parking lot images mb-1">
                                                    <div className={`markingoptions ${selectedOptions.includes("DOL - Drop Off Location") ? 'selected' : ''}`}
                                                        onClick={() => handleCheckboxChange("DOL - Drop Off Location")}>

                                                        <span>DOL - Drop Off Location</span>
                                                    </div>
                                                    <div className={`markingoptions ${selectedOptions.includes("PUX - Pick Up Exit") ? 'selected' : ''}`}
                                                        onClick={() => handleCheckboxChange("PUX - Pick Up Exit")}>

                                                        <span>PUX - Pick Up Exit</span>
                                                    </div>
                                                    <div
                                                        className={`markingoptions ${selectedOptions.includes("DOX - Drop Off Exit") ? 'selected' : ''}`}
                                                        onClick={() => handleCheckboxChange("DOX - Drop Off Exit")}>

                                                        <span>DOX - Drop Off Exit</span>
                                                    </div>
                                                </div>

                                                <Typography gutterBottom>
                                                    <span className="markingsubheader"> Parking Space Locations</span>
                                                </Typography>
                                                <div className="Parking lot images mb-2">
                                                    <div className={`markingoptions ${selectedOptions.includes("PKE - Parking Entrance") ? 'selected' : ''}`}
                                                        onClick={() => handleCheckboxChange("PKE - Parking Entrance")} >
                                                        <span>PKE - Parking Entrance</span>
                                                    </div>
                                                    <div
                                                        className={`markingoptions ${selectedOptions.includes("PKX - Parking Exit") ? 'selected' : ''}`}
                                                        onClick={() => handleCheckboxChange("PKX - Parking Exit")}>

                                                        <span>PKX - Parking Exit</span>
                                                    </div>
                                                    <div className={`markingoptions ${selectedOptions.includes("PSL - Parking Space Location") ? 'selected' : ''}`}
                                                        onClick={() => handleCheckboxChange("PSL - Parking Space Location")}>
                                                        <span >PSL - Parking Space Location</span>
                                                    </div>

                                                </div>

                                                <br />
                                            </div>
                                            {hasselectedError && (
                                            <div style={{ color: 'red', marginTop: '-15px', marginRight: '250px' }}>
                                                 Please Select Atleast one option!
                                            </div>
                                        )}
                                        </DialogContent>
                                        <DialogActions sx={{ justifyContent: 'end' }}>
                                            <Button className='secondary' autoFocus onClick={handleClose} classes={{ root: 'Secondary' }}>
                                                <span style={{ textTransform: 'lowercase' }}>Confirm</span>
                                            </Button>
                                            <Button className='secondary' autoFocus onClick={handleCancel} classes={{ root: 'Secondary' }}>
                                                <span style={{ textTransform: 'lowercase' }}>Cancel</span>
                                            </Button>
                                        </DialogActions>
                                    </BootstrapDialog>
                                </div>
                            </div>
                        ) : (
                            !bankdetails ? (
                                <ScheduleAvailability handleConfirm={handleConfirm} handlebankdetails={handlebankdetails}
                                    handlebankaccountdetails={handlebankaccountdetails} handleradiobutton={handleradiobutton}
                                    selectedValue={selectedValue} errors={errors} timeValues={timeValues} handleChange={handleChange}
                                    handleStepsback={handleBack}  activeStep={activeStep} timeSlotesAvailability ={timeSlotesAvailability}
                                    EmailId={stateLogedInUser?.stateLogedInUser?.stateLogedInUser?.LogedInUser[0]?.emailId}
                                />
                            ) : (
                                !reviewlocation ? (
                                    <BankDetailsMain handlebankdetails={handlebankdetails} bankdetails={bankaccountdetails}
                                        handleReview={handleReview} validateFields={validateFields} handleInputChange={handleInputChange}
                                        errors={errorsbank} accountinfo={accountinfo} activeStep={activeStep} handleSteps={handleSteps} handleStepsback={handleBack}
                                    />) : (
                                    <ReviewAndConfirm handleReview={handleReview} areasMarked={areasMarked} addressvalue={addressvalue}
                                        accountinfo={accountinfo} handleSteps={handleSteps} handleStepsback={handleBack}
                                        bankdetails ={accountinfo}
                                        EmailId={stateLogedInUser?.stateLogedInUser?.stateLogedInUser?.LogedInUser[0]?.emailId}
                                        />
                                )
                            )
                        )}
                    </div>
                </div>
            </div>

        </div>
    );
};
export default LocateProperty;