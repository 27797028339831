import React, { useEffect, useState }from 'react';
import MyWorklist from './MyWorklist';
import ApprovedProperties from './ApprovedProperties';
import Navbar from '../NavBar/Navbar';
import './VerifyAlert.css';
import FadeLoader from "react-spinners/FadeLoader";

const ReviewTab = () => {
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const [activeTab, setActiveTab] = useState('tab1'); 
    const [tabOneCount, setTabOneCount] = useState(0); 
    const [tabTwoCount, setTabTwoCount] = useState(0); 
    const [isEdit, setEdit] = useState(false);
    const [loader, setLoader] = useState(false);
    const [parkingLotBookingDetails, setParkingLotBookingDetails] = useState([]);
    const handleEdit = (val) => {
        setEdit(val);
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const handlePendingCount = (val) => {
        setTabOneCount(val);
    };
    
    useEffect(() => {
        const fetchParkinglotDetails = async () => {
            setLoader(true);
            try {
                const response = await fetch(`${hostPath}/api/GetAllParkingLots?status=Active`, {
                    method: 'GET',
                    headers: { "x-functions-key": `${hostHeader}` },
                });
                setLoader(false);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setTabTwoCount(data.length);
                setParkingLotBookingDetails(data);
            } catch (error) {
                setLoader(false);
                console.error('Failed to fetch user details:', error);
            }
        };

        fetchParkinglotDetails();
    }, [hostPath]);
    return (
        <div>
            <Navbar />
            {!isEdit && (
                <div className="tab-titles">
                    <button
                        className={activeTab === 'tab1' ? 'active' : ''}
                        onClick={() => handleTabChange('tab1')}
                    >
                        Pending review <span className={`tab-count ${activeTab === 'tab1' ? 'active-count' : ''}`}>{tabOneCount}</span>  {/* Display count next to Tab One */}
                    </button>
                    <button
                        className={activeTab === 'tab2' ? 'active' : ''}
                        onClick={() => handleTabChange('tab2')}
                    >
                        Active Properties <span className={`tab-count ${activeTab === 'tab2' ? 'active-count' : ''}`}>{tabTwoCount}</span>
                    </button>
                </div>
            )}
            <div className="tab-content-container">
                {activeTab === 'tab1' && (
                    <MyWorklist handlePendingCount={handlePendingCount} handleEdit={handleEdit} isEdit={isEdit} />
                )}
                {activeTab === 'tab2' && (
                    <ApprovedProperties parkingLotBookingDetails={parkingLotBookingDetails} />
                )}
            </div>
            {loader && (
                <div className="loader-overlay">
                    <FadeLoader />
                </div>
            )}
        </div>
    );
};

export default ReviewTab;
