import React, { useEffect, useState } from 'react';
import DashBoardHolder from '../../Images/dashboardPlaceholder.svg';
import Navbar from '../NavBar/Navbar';
import MapDashboard from '../myproporties/MapDashboard';
import { useLocation,useNavigate } from 'react-router-dom';
import API_URLS from '../apiCalls/apiUrls';
import {removeDoublequotesPattern} from '../utils/regexPatterns'
const Dashboard = (props) => {
    const navigate = useNavigate();
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const [userData, setUserdata] = useState();
    const [isdashboard, setDashboard] = useState(true);
    const [mapvisible, setMapvisible] = useState(false);
    const [defaultaddress, setDefaultaddress] = useState();
    const location = useLocation();
    const [internalEmployeeEmail, setInternalEmployeeEmail] = useState('');
    useEffect(() => {
        const storedEmail = JSON.parse(localStorage.getItem('companyEmail'));
        setInternalEmployeeEmail(storedEmail);
    }, []);
    const RegisterProperty = (e) => {
        if(internalEmployeeEmail?.includes('@geoparking.com')){
        navigate('/OwnerRegistationDashboard');
        }
        else{
        e.preventDefault();
        setDashboard(false);
        handleMapvisible(true);
     }
}
    const handleMapvisible = (val) => {
        setMapvisible(val);
    }
    useEffect(() => {
        if (location.state?.isDashboard !== undefined) {
            setDashboard(location.state.isDashboard);
        }
    }, [location.state]);
    function getLoggedInUser() {
        const loggedInUser = localStorage.getItem('LogedInUser');
        if (!loggedInUser) {
            return null;
        }
        try {
            const stateLoggedInUser = JSON.parse(loggedInUser);
            return stateLoggedInUser;
        } catch (error) {
            return null;
        }
    }
    const companyEmail = localStorage.getItem('companyEmail')
    const stateLogedInUser = getLoggedInUser();
    const [entityId, setEntityId] = useState();
    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await fetch(`${hostPath}/api/GetUserDetailsbyid`, {
                    method: 'POST',
                    headers: { "x-functions-key": `${hostHeader}` },
                    body: JSON.stringify({ EntityRegistrationID: stateLogedInUser }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setUserdata(data[0])
                setEntityId(stateLogedInUser);
                setDefaultaddress(data[0]?.enityMailingAdderess);
            } catch (error) {
            }
        };
        const fetchGetInternalEmployeeDetailsByEmailId = async () => {
            const internalEmployeeEmail = removeDoublequotesPattern(companyEmail);
            try {
                const response = await fetch(`${hostPath}${API_URLS.BASE_URL_InternalEmployeeDetailsByEmailId}`, {
                    method: 'POST',
                    headers: { "x-functions-key": `${hostHeader}` },
                    body: JSON.stringify({
                        CompanyEmail : internalEmployeeEmail}),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
               const internalEmployeeData = await response.json();
               setUserdata(internalEmployeeData[0])
            } catch (error) {
            }
        };
        if(companyEmail){
            fetchGetInternalEmployeeDetailsByEmailId();
        } else{
            fetchUserDetails();
        }
    }, [stateLogedInUser]); 
    return (
        <div>
            <Navbar mapvisible={mapvisible} userData={userData} />
            {isdashboard ? (
                <div className="container-fluid p-0">
                  
                    <div className="dashboard">
                        <div className="rectangle" />
                        <div className="rectangle-2" />
                    </div>
                    <div className="dashboardContainer-fluid DashBoardBody">
                        <div className="illustration-auntum">
                            <img className="clip-path-group" alt="Clip path group" src={DashBoardHolder} />
                        </div>
                        <div>
                            <p className="dashboardheaders">Welcome {userData?.firstName ? userData?.firstName : "User"} to Your GeoParking Dashboard!</p>
                            <p className="we-re-thrilled-to">
                                We're thrilled to have you on board. With GeoParking, managing your parking lots has never been easier. Let's maximize your potential together!
                            </p>
                        </div>
                        <div className="registerframe">
                            <button className='reg-property' onClick={RegisterProperty}>
                            {internalEmployeeEmail?.includes('@geoparking.com') ? 'Register Owner' : 'Register Property'}
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <MapDashboard userData={userData} entityId={entityId} defaultaddress={defaultaddress} />
            )}
        </div>
    );
};

export default Dashboard;
