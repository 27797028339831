import React, { useState } from 'react';
import './OwnerRegistration.css';
import PropertImage from '../../Images/Property.png';
import { RxDotsVertical } from 'react-icons/rx'; // Import the dots icon

const OwnerRegistartionCard = ({ ownerName, property,entityName, email, mobilePhone, entityAddress, handleDelete, handleEdit, handleSelectedowner,handleRegsiterOwner,entityId }) => {
  const [showMenu, setShowMenu] = useState(false);

  // Toggle menu visibility
  const handleDotsClick = (event) => {
    event.stopPropagation(); // Prevents triggering handleSelectedowner
    setShowMenu(!showMenu); // Toggle menu
  };

  const handleOptionClick = (event) => {
    event.stopPropagation();
    setShowMenu(false); // Close the menu after selecting an option
  };

  return (
    <div className="property-card" onClick={handleSelectedowner} style={{ position: 'relative' }}>
      <div className="property-content">
        <div className="owner-details">
          <img src={PropertImage} alt="Owner" className="owner-icon" />
        </div>
        <div className="property-info">
          <div className="info-block">
            <p className="info-header">Owner Name</p>
            <p className="info-data">{ownerName}</p>
          </div>
          <div className="info-block">
            <p className="info-header">Entity Name</p>
            <p className="info-data">{entityName}</p>
          </div>
          <div className="info-block">
            <p className="info-header">Email</p>
            <p className="info-data">{email}</p>
          </div>
          <div className="info-block">
            <p className="info-header">Mobile Phone</p>
            <p className="info-data">{mobilePhone}</p>
          </div>
          <div className="info-block">
            <p className="info-header">Entity Address</p>
            <p className="info-data" title={entityAddress}>{entityAddress.length > 40 ? `${entityAddress.slice(0, 20)}...` : entityAddress}</p>
        </div>

        </div>
        <div className="dots-menu" onClick={handleDotsClick} style={{ cursor: 'pointer' }}>
          <RxDotsVertical size={28} />
        </div>
        {showMenu && (
          <div className="dropdown-menu1">
            <div className="dropdown-option" onClick={(e) => handleRegsiterOwner(e,true,email,entityId)}>Edit Details</div>
            <div className="dropdown-option" onClick={(e) => handleEdit(e,property)}>Add Property</div>
            <div className="dropdown-option" onClick={(e) => handleDelete(e,property)}>Delete Owner</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OwnerRegistartionCard;
