import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, Button, DialogTitle } from '@mui/material';

const BootstrapDialogpopup = ({ open, EmailPopUp, onClose, initialEmail, onVerify, destinationType, InValidOTP, OTPValidation, sendOTPByEmail, sendOTPByPhone }) => {
  const [otp, setOtp] = useState(new Array(5).fill(""));
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;
    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);
    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  useEffect(() => {
    if (open || !EmailPopUp) {
      setOtp(new Array(5).fill(""));
    }
  }, [open,EmailPopUp]);

  const ResendOTP = () => {
    if (EmailPopUp) {
      sendOTPByEmail();
      setOtp(new Array(5).fill(""));
    } else {
      sendOTPByPhone();
      setOtp(new Array(5).fill(""));
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" className="dialog-header">
      <DialogContent dividers className="dialog-body">
        <DialogTitle className='text-center'>{EmailPopUp ? "Email Verification" : "Phone Verification"}</DialogTitle>
        <p>An OTP has been sent to <strong className='textcolor'>{initialEmail}</strong></p>
        <p>Enter the 5-digit code below to verify the {destinationType}.</p>

        <div className="d-flex justify-content-center">
          {otp?.map((data, index) => (
            <input key={index} type="text" maxLength="1" value={data} onChange={(e) => handleChange(e.target, index)} className='otp-inputbox'
            />
          ))}
        </div>

        <Button variant="contained" className="w-100 mt-3 verify-btn verify-otpbtn" onClick={() => onVerify(otp.join(''))} > Verify </Button>

        <div className="text-center mt-3">
          <small> Didn't get the code? <Button variant="text" onClick={ResendOTP} className='resend-btn' > Resend OTP </Button> </small>
          <div className="validation-error text-center">{InValidOTP}</div>
          <div className="validation-error text-center">{OTPValidation}</div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default BootstrapDialogpopup;
