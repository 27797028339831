import CryptoJS from 'crypto-js';

const key = CryptoJS.enc.Utf8.parse('qwr{@^h`h&_`50/ja9!\'dcmh3!uw<&=?'); // Must be 32 bytes
const iv = CryptoJS.enc.Utf8.parse('9/\\~V).A,lY&=t2b'); // Must be 16 bytes

export const encryptPassword = (plainText) => {
    const encrypted = CryptoJS.AES.encrypt(plainText, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
};

export const decryptPassword = (cipherText) => {
    const decrypted = CryptoJS.AES.decrypt(cipherText, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
};
