import { DataGrid } from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react';

export default function GlobalSearchDataTable(props) {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props.properties) {
      const updatedRows = props.properties.map((item, index) => ({
        ...item,
        id: item.entityRegistrationID || `row-${index}`,
      }));
      setRows(updatedRows);
    }

    if (props.columns) {
      const updatedColumns = props.columns.map((column) => ({
        ...column,
        disableColumnMenu: true,
        editable: false,
      }));
      setColumns(updatedColumns);
    }
  }, [props.properties, props.columns]);


  return (
    <div className="gridtable">
      <DataGrid
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        className="custom-data-grid"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
      />
    </div>
  );
}
