import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';;
const BankDetailsMain = (props) => {
    const { overrides, bankdetails, handleReview, validateFields, handleInputChange, errors, accountinfo, toggleCondition, handleSave, isSaveDisabled, ...rest } = props;
    const navigate = useNavigate();
    const accountTypes = [
        'Checking Account',
        'Savings Account',
        'Money Market Account',
        'Certificate of Deposit (CD)',
        'Brokerage Account',
        'Individual Retirement Account (IRA)',
        'Joint Account',
        'Business Account'
    ];
    const handleButtonClick = (e) => {
        if (validateFields()) {
            handleReview(true);
            toggleCondition('4 of 5 - Enter Bank Details')
            toggleCondition('5 of 5 - Review and Confirm')

        }
    };
    const handlebankdetailsSave = (e) => {
        if (validateFields()) {
            handleSave(e);
        }
    }
    const backButtonClick = () => {
        props.handlebankdetails(false);
        toggleCondition('4 of 5 - Enter Bank Details')
        toggleCondition('3 of 5 - Schedule Availability')
    }
    return (
        <div>
            <div className="col-md-10 ">
                <div className="row m-4">
                    <div className="col-lg-12 col-md-12 col-sm-12 m-3" style={{ textAlign: "left" }}>
                        <div><b>Enter Bank Details for Rent Payments for Robotaxies/AVs to park</b></div>
                        <div>Since you have selected to be paid for Robotaxis to park in your parking lot, payment can only be made via ACH bank transfer. Please fill in the below information to receive payments</div>
                    </div>
                </div>

                <div className="row m-3">
                    <div className="col-md-12">
                        <div className="bnkDetailsForm" style={{ height: "400px" }}>
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-3 form-group m-4">
                                    <label htmlFor="Bank_Name">Bank Name</label><span className='mandatorymark loginlabel'>*</span>
                                    <input
                                        type="text"
                                        className={`form-control ${accountinfo.BankName ? 'input-active' : ''}`}
                                        id="Bank_Name"
                                        maxLength={30}
                                        placeholder="Enter Bank Name"
                                        onInput={(e) => {
                                            const value = e.target.value;
                                            const filteredValue = value.replace(/[^a-zA-Z\s]/g, '');
                                            e.target.value = filteredValue;
                                            handleInputChange("BankName")(e);
                                        }}
                                        value={accountinfo.BankName}
                                        name="BankName"
                                    />
                                    {errors.BankName && <div className="error-message mandatorymark">{errors.BankName}</div>}
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 form-group m-4">
                                    <label htmlFor="Account_Holder_Name">Account Holder Name</label><span className='mandatorymark loginlabel'>*</span>
                                    <input
                                        type="text"
                                        className={`form-control ${accountinfo.AccountHolderName ? 'input-active' : ''}`}
                                        id="Account_Holder_Name"
                                        maxLength={30}
                                        placeholder="Enter Account Holder Name"
                                        onInput={(e) => {
                                            const value = e.target.value;
                                            const filteredValue = value.replace(/[^a-zA-Z\s]/g, '');
                                            e.target.value = filteredValue;
                                            handleInputChange("AccountHolderName")(e);
                                        }}
                                        value={accountinfo.AccountHolderName}
                                        name="AccountHolderName"
                                    />
                                    {errors.AccountHolderName && <div className="error-message mandatorymark">{errors.AccountHolderName}</div>}
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 form-group m-4">
                                    <label htmlFor="Account_Number">Account Number</label><span className='mandatorymark loginlabel'>*</span>
                                    <input
                                        type="text"
                                        maxLength={12}
                                        className={`form-control ${accountinfo.AccountNumber ? 'input-active' : ''}`}
                                        id="Account_Number"
                                        placeholder="Enter Account Number"
                                        onInput={(e) => {
                                            const value = e.target.value;
                                            const filteredValue = value.replace(/[^0-9]/g, '');
                                            e.target.value = filteredValue;
                                            handleInputChange("AccountNumber")(e);
                                        }}
                                        value={accountinfo?.AccountNumber}
                                        name="AccountNumber"
                                    />
                                    {errors.AccountNumber && <div className="error-message mandatorymark">{errors.AccountNumber}</div>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-3 form-group m-4">
                                    <label htmlFor="Routing_Number">Routing Number</label><span className='mandatorymark loginlabel'>*</span>
                                    <input
                                        type="text"
                                        className={`form-control ${accountinfo.RoutingNumber ? 'input-active' : ''}`}
                                        id="Routing_Number"
                                        placeholder="Enter Routing Number"
                                        maxLength={9}
                                        onInput={(e) => {
                                            const value = e.target.value;
                                            const filteredValue = value.replace(/[^0-9]/g, '');
                                            e.target.value = filteredValue;
                                            handleInputChange("RoutingNumber")(e);
                                        }}
                                        value={accountinfo?.RoutingNumber}
                                        name="RoutingNumber"
                                    />
                                    {errors.RoutingNumber && <div className="error-message mandatorymark">{errors.RoutingNumber}</div>}
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 form-group m-4">
                                    <label htmlFor="SWIFT_BIC_Code">SWIFT/BIC Code (if applicable)</label>
                                    <input
                                        type="text"
                                        className={`form-control ${accountinfo.SWIFT_BIC_Code ? 'input-active' : ''}`}
                                        id="SWIFT_BIC_Code"
                                        maxLength={11}
                                        placeholder="Enter SWIFT/BIC Code (if applicable)"
                                        onInput={(e) => {
                                            const value = e.target.value;
                                            const filteredValue = value.replace(/[^0-9]/g, '');
                                            e.target.value = filteredValue;
                                            handleInputChange("SWIFT_BIC_Code")(e);
                                        }}
                                        value={accountinfo?.SWIFT_BIC_Code}
                                        name="SWIFT_BIC_Code"
                                    />
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 form-group m-4">
                                    <label htmlFor="Account_Type">Account Type</label><span className='mandatorymark loginlabel'>*</span>
                                    <input
                                        list="accountTypes"
                                        className={`form-control ${accountinfo.AccountType ? 'input-active' : ''}`}
                                        id="Account_Type"
                                        placeholder="Select or Type Account Type"
                                        onInput={
                                            (e) => {
                                                const value = e.target.value;
                                                const filteredValue = value.replace(/[^a-zA-Z\s]/g, '');
                                                e.target.value = filteredValue;
                                                handleInputChange("AccountType")(e);
                                            }}
                                        value={accountinfo.AccountType}
                                        name="AccountType"
                                    />
                                    <datalist id="accountTypes">
                                        {accountTypes.map((type, index) => (
                                            <option key={index} value={type} />
                                        ))}
                                    </datalist>
                                    {errors.AccountType && <div className="error-message mandatorymark">{errors.AccountType}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="mb-3" style={{ textAlign: "end" }}>
                        <button type="submit" className={`autonextBtn ${isSaveDisabled ? 'disabled' : ''}`}
                            onClick={handlebankdetailsSave}
                            style={{
                                pointerEvents: isSaveDisabled ? 'none' : 'auto',
                                opacity: isSaveDisabled ? 0.5 : 1,
                                backgroundColor:'#6acd54',
                                marginRight:'10px'
                            }}
                        >Save</button>
                        <button type="submit" className='finishbtn' onClick={backButtonClick} style={{ marginRight: "10px" }}>Go Back</button>
                        <button type="submit" className='autonextBtn' onClick={handleButtonClick}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BankDetailsMain;
