import React, { useState, useEffect } from "react";
import Navbar from "./NavBar/Navbar";
import Button from '@mui/material/Button';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [userData, setUserData] = useState([]);
  const [selectedEmailIds, setSelectedEmailIds] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [errors, setErrors] = useState([]);
  const hostPath = process.env.REACT_APP_API_BASE_URL;
  const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requestBody = {
          EmailId: "",
          FirstName: "",
          LastName: "",
          EntityName: "",
        };
        setSelectedEmailIds([]); 
        const response = await fetch(
          `${hostPath}/api/SearchUserDetails`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-functions-key": hostHeader,
            },
            body: JSON.stringify(requestBody),
          }
        );
        const searchResults = await response.json();
        const filteredResults = searchResults.filter(user => user.isVerified === false);
        if (filteredResults.length === 0 || filteredResults.length === undefined) {
          setUserData([]);
        } else {
          setUserData(filteredResults);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); 

  const handleSelectUser = (userId) => {
    setSelectedUsers(prevSelected => {
      if (prevSelected.includes(userId)) {
        return prevSelected.filter(id => id !== userId);
      } else {
        return [...prevSelected, userId];
      }
    });
  };

  const handleVerification = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    if (selectedEmailIds.length === 0) {
        setErrors("Please select at least one user to verify.");
        return;
    }

    try {
        await Promise.all(
            selectedEmailIds.map((emailId) =>
                fetch(`${hostPath}/api/UpdateUserDetails`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-functions-key": hostHeader,
                    },
                    body: JSON.stringify({ EmailId: emailId, isVerified: true }),
                })
            )
        );

        alert("Users have been verified.");
        window.location.reload(); 
        setSelectedEmailIds([]);
        setErrors("");
    } catch (error) { 
        console.error("Error verifying users:", error);
        alert("An error occurred during the verification process. Please try again.");
    }
};

  return (
    <div>
      <Navbar />
      <p style={{ marginLeft: "20px", marginTop: "20px", textAlign: "left", fontSize: "20px", }}>
        Select the users you want to verify:
        <button 
        style={{ marginLeft: "20px", marginTop: "20px", padding: "5px 10px", fontSize: "14px", textAlign: "left", backgroundColor: "blue", color: "#fff" }} 
        onClick={() => window.location.reload()}
        >
        Refresh
        </button>
      </p>
      {errors && (
        <div className="error-message mandatorymark" style={{textAlign: "left", marginLeft: "20px"}}>
          {errors}
        </div>
      )}
      {userData.length > 0 && (
                <div className="table-responsive"style={{marginLeft: "20px", marginTop: "20px"}}>
                  <table className="table table-bordered table-striped">
                    <thead className="thead-dark">
                      <tr>
                        <th>Select</th>
                        <th>Email ID</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Entity Name</th>
                      </tr>
                    </thead>
                    <tbody>
                    {userData.map((user) => (
                      <tr key={user.id}>
                        <td>
                          {user.isVerified ? (
                            <span>&#10003;</span> // This is a simple checkmark, replace with your preferred icon
                          ) : (
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedEmailIds((prev) => [...prev, user.emailId]);
                                } else {
                                  setSelectedEmailIds((prev) => prev.filter((emailId) => emailId !== user.emailId));
                                }
                              }}
                            />
                          )}
                        </td>
                        <td>{user?.emailId}</td>
                        <td>{user?.firstName}</td>
                        <td>{user?.lastName}</td>
                        <td>{user?.entityName}</td>
                      </tr>
                    ))}
                  </tbody>
                  </table>
                  <Button
                    style={{marginTop: "20px" }}
                    className='custom-button-cancel'
                    type="button"
                    onClick={handleVerification}
              >
                Verify
              </Button>
               </div>        
              )}
    </div>
  );
};

export default UserList;