const API_URLS = {
    BASE_URL_SEND_OTP_TO_EMAIL: "/api/SendOtpToEmail",
    BASE_URL_LOGIN_USER: "/api/LoginUser",
    BASE_URL_VERIFY_OTP: "/api/VerifyOtp",
    BASE_URL_InternalEmployeeDetailsByEmailId: "/api/GetInternalEmployeeDetailsByEmailId",
    BASE_URL_RegisterInternalEmployee: "/api/RegisterInternalEmployee",
    BASE_URL_UpdatePassword: "/api/UpdatePassword",
    BASE_URL_CheckInternalEmployeeExist: "/api/CheckInternalEmployeeExist",
    BASE_URL_GetSearchProperty: "/api/GetSearchProperty"
  };
  
  export default API_URLS;